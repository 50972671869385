import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { useLayoutTarget } from '../hooks';
import useOnClickOutside from 'use-onclickoutside';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  content: React.ReactNode;
  render?: (setRef: (el: HTMLElement | null) => void) => React.ReactNode;
  isVisible?: boolean;
  setVisible?: (val: boolean) => void;
  className?: string;
  tooltipClassName?: string;
  placement?: Placement;
  isOutsideClose?: boolean;
}

const carpetSize = parseFloat(styles.carpetSize);

const Tooltip: React.FC<TooltipProps> = ({
  content,
  isVisible = false,
  isOutsideClose = false,
  children,
  className,
  tooltipClassName,
  placement = 'top',
  render,
  setVisible,
}) => {
  const layoutRef = useLayoutTarget();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const modifiers = useMemo(
    () => [
      { name: 'arrow', options: { element: arrowElement, padding: carpetSize / 2 } },
      { name: 'offset', options: { offset: [carpetSize / 2, carpetSize / 2] } },
    ],
    [arrowElement],
  );
  const {
    styles: elStyles,
    attributes,
    update,
  } = usePopper(isVisible ? referenceElement : null, popperElement, {
    placement,
    modifiers,
  });

  const onResize = useCallback(() => {
    if (update) {
      update();
    }
  }, [update]);

  const { ref } = useResizeDetector({
    onResize,
  });

  const onClose = useCallback(() => {
    setVisible && setVisible(false);
  }, [setVisible]);

  const wrapperRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (isOutsideClose) {
      wrapperRef.current = popperElement;
    }
  }, [popperElement, isOutsideClose]);

  useOnClickOutside(wrapperRef, onClose);

  return (
    <>
      {render ? (
        render(setReferenceElement)
      ) : (
        <div className={cn(styles.tooltipWrapper, className)} ref={setReferenceElement}>
          {children}
        </div>
      )}
      {isVisible &&
        layoutRef.current &&
        ReactDOM.createPortal(
          <div
            className={cn(styles.tooltip, tooltipClassName)}
            style={elStyles.popper}
            ref={setPopperElement}
            {...attributes.popper}
          >
            <div className={styles.tooltipContent} ref={ref}>
              {content}
            </div>
            <div className={styles.tooltipArrow} style={elStyles.arrow} ref={setArrowElement} />
          </div>,
          layoutRef.current,
        )}
    </>
  );
};

export default Tooltip;
