import { RootState } from './';
import { State } from './session';
import { ID } from './models';

export const getSessionStore = (store: RootState) => store.session;

export const getUserStore = (store: RootState) => store.users;

export const getNotificationsStore = (store: RootState) => store.notifications;

export const getNotifications = (store: RootState) => getNotificationsStore(store).notifications;

export const getLoggedIn = (store: RootState) => !!getSessionStore(store).currentUser;

export const getCurrentUser = (store: RootState) => getSessionStore(store).currentUser;

export const getCurrentUserName = (store: RootState) => getCurrentUser(store)?.name;

export const getCurrentUserId = (store: RootState) => getCurrentUser(store)?._id;

export const getToken = (store: RootState) => getSessionStore(store).token;

export const getFlag = (flag: keyof State['flags']) => (store: RootState) =>
  getSessionStore(store).flags[flag];

export const getUser = (_id: ID) => (store: RootState) => getUserStore(store).usersById[_id];

export const getUserName = (_id: ID) => (store: RootState) => getUser(_id)(store).name;

export const getUserCreds = (_id: ID) => (store: RootState) => {
  const userName = getUserName(_id)(store);

  const [fisrtName, lastName] = userName.split(' ');

  return `${fisrtName[0]}${lastName?.[0] ?? ''}`.trim();
};

export const slideVote = (cacheKey: string, _id: ID) => (store: RootState) =>
  getSessionStore(store).slideVotes[`${cacheKey}-${_id}`];
