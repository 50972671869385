import { useMemo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { User, ID } from 'store/models';
import Avatar from 'ui/Avatar';
import Button from 'ui/Button';
import { useLayoutTarget } from 'ui/hooks';
import ExtendedUserList from './ExtendedUserList';

import styles from './UsersList.module.scss';
import fromRightSide from 'ui/transitions/FromRightSide.module.scss';

interface UsersListProps {
  users: User[];
  userPointers: Set<ID>;
  isMeetActions: boolean;
  setPointer: (val: { userId: ID; value: boolean }) => void;
}

const UsersList: React.FC<UsersListProps> = ({
  users,
  userPointers,
  isMeetActions,
  setPointer,
}) => {
  const [isExtended, setIsExtended] = useState(false);
  const nodeRef = useRef(null);
  const shortUserList = useMemo(() => {
    return [...users].slice(0, 4);
  }, [users]);
  const layoutRef = useLayoutTarget();

  return (
    <div className={styles.wrapper}>
      {shortUserList.map((user) => (
        <Avatar key={user._id} className={styles.avatar} name={user.name} />
      ))}
      <Button
        onClick={() => setIsExtended((val) => !val)}
        isRounded
        isBordered
        color="secondary"
        size="sm"
      >
        {isExtended ? '✕' : '···'}
      </Button>

      <CSSTransition
        in={!!(isExtended && layoutRef.current)}
        nodeRef={nodeRef}
        mountOnEnter
        unmountOnExit
        timeout={300}
        classNames={fromRightSide}
      >
        <>
          {layoutRef.current &&
            createPortal(
              <ExtendedUserList
                ref={nodeRef}
                isMeetActions={isMeetActions}
                users={users}
                userPointers={userPointers}
                setPointer={setPointer}
              />,
              layoutRef.current,
            )}
        </>
      </CSSTransition>
    </div>
  );
};

export default UsersList;
