import { useTranslation } from 'i18n-lite';
import { Link } from 'react-router-dom';

import styles from './Pages.module.scss';

const NotFound: React.FC = () => {
  const { t } = useTranslation('404Page');

  return (
    <div className={styles.placeholderPage}>
      <div>
        {t('header')}
        <br />
        <Link replace to="/">
          {t('homeButton')}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
