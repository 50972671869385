import cn from 'classnames';
import { useTranslation } from 'i18n-lite';

import { ID, CurrentPresentation, Presentation, isPresentation } from 'store/models';
import PresentSelect from './PresentSelect';
import SlidePager from './SlidePager';
import Voting from './Voting';
import ShareDropdown from '../ShareDropdown';

import { ReactComponent as DownloadIcon } from 'assets/DownloadIcon.svg';
import { ReactComponent as ShareIcon } from 'assets/ShareIcon.svg';
import { ReactComponent as CommentsIcon } from 'assets/CommentsIcon.svg';
import { ReactComponent as FullscreenIcon } from 'assets/FullscreenIcon.svg';

import styles from './PresenterLayout.module.scss';

interface FooterToolbarProps {
  className?: string;
  presentations: Presentation[];
  currentPresentation?: CurrentPresentation;
  setCurrentPresent: (id: ID) => void;
  slideCounts?: number;
  currentSlide: number;
  goTo: (e: number) => void;
  isLikes?: boolean;
  isFullscreen: boolean;
  onFullscreen?: () => any;
  isShowFullscreen?: boolean;
  setZoom: (zoom: number) => any;
  zoom: number;
  onVote?: (e: 'like' | 'dislike' | undefined) => any;
  voteTo?: 'like' | 'dislike';
  isActiveComments: boolean;
  toggleComments: () => void;
}

const SelfDetailingFooter: React.FC<FooterToolbarProps> = ({
  className,
  slideCounts,
  currentSlide,
  goTo,
  presentations,
  currentPresentation,
  setCurrentPresent,
  onFullscreen,
  isShowFullscreen = false,
  isFullscreen,
  isLikes,
  voteTo,
  onVote,
  isActiveComments,
  toggleComments,
}) => {
  const { t } = useTranslation();

  return (
    <footer className={cn(styles.footer, className)}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerLeft}>
          <PresentSelect
            direction="top"
            variant="light"
            presentations={presentations}
            currentPresentation={currentPresentation}
            setCurrentPresent={setCurrentPresent}
          />
          {isShowFullscreen && (
            <button
              className={cn(styles.headerActionButton, styles.headerActionElement)}
              onClick={onFullscreen}
            >
              <FullscreenIcon
                className={cn(
                  styles.headerFullscreenIcon,
                  isFullscreen && styles.headerFullscreenIconActive,
                )}
              />
            </button>
          )}
        </div>
        <div className={styles.footerCenter}>
          {slideCounts && <SlidePager current={currentSlide} count={slideCounts} goTo={goTo} />}
        </div>
        <div className={styles.footerRight}>
          {isPresentation(currentPresentation) && currentPresentation.pdfLink && (
            <a
              target="_blank"
              rel="nofollow noreferrer"
              href={currentPresentation.pdfLink}
              className={cn(styles.headerActionButton, styles.headerActionElement)}
              title={t(':ui.download')}
              download={`${currentPresentation.pdfName ?? currentPresentation.externalId}.pdf`}
            >
              <DownloadIcon />
            </a>
          )}
          <ShareDropdown
            className={cn(styles.headerActionElement, styles.headerSelect)}
            direction="top"
            render={({ toggleOpen }) => (
              <button
                className={cn(styles.headerActionButton, styles.headerActionElement)}
                onClick={toggleOpen}
              >
                <ShareIcon />
              </button>
            )}
          />
          {isLikes && <Voting voteTo={voteTo} onVote={onVote} />}
          <button
            className={cn(
              styles.headerActionButton,
              styles.headerActionElement,
              isActiveComments && styles.headerActionButtonActive,
              styles.commentButton,
            )}
            onClick={toggleComments}
          >
            <CommentsIcon />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default SelfDetailingFooter;
