import I18n, { getLangDetector } from 'i18n-lite';

import en from './locales/en.json';
import ru from './locales/ru.json';

const { detectLang, langHandler } = getLangDetector();

const i18n = new I18n({
  lang: detectLang(),
  fallbackLng: 'en',
  resource: {
    en,
    ru,
  },
});

i18n.on('langChange', langHandler);

export default i18n;
