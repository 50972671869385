import { useMemo, useEffect, useCallback, useState } from 'react';
import { useFullscreen, usePrevious } from 'rooks';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store';
import { slideVote } from 'store/selectors';
import { useAnalytic } from 'hooks/analytic';
import useEventHandler from 'hooks/useEventHandler';
import { usePublicCtmSdk, useDescribePublicAppSdk } from 'sdk';
import { DetailingEnv, UserType } from 'sdk/Ctm';
import {
  ID,
  Slide,
  Meeting,
  CurrentPresentation,
  Presentation,
  PresentationScenario,
  isPresentationScenario,
} from 'store/models';

export function useCurrentPresent({
  detailingEnv,
  userType,
  meet,
  currentPresentationId,
  currentSlideId,
}: {
  userType: UserType;
  detailingEnv: DetailingEnv;
  meet: Meeting;
  currentPresentationId: ID | null;
  currentSlideId: ID | null;
}) {
  const trackEvent = useAnalytic();
  const flatPresentations = useMemo(
    () =>
      meet.presentations.reduce((acc, item) => {
        acc.set(item._id, item);

        if (item.scenarios.length) {
          item.scenarios.forEach((scen) => {
            acc.set(scen._id, scen);
          });
        }

        return acc;
      }, new Map<ID, Presentation | PresentationScenario>()),
    [meet.presentations],
  );

  const currentPresentation = useMemo(
    () => (currentPresentationId ? flatPresentations.get(currentPresentationId) : undefined),
    [currentPresentationId, flatPresentations],
  );

  const currentSlide = useMemo(
    () => currentPresentation?.slides.find((slide) => currentSlideId === slide._id),
    [currentPresentation?.slides, currentSlideId],
  );

  const currentSlides = useMemo(() => {
    if (!currentPresentation) {
      return;
    }

    if (isPresentationScenario(currentPresentation) && currentPresentation.isCombined) {
      return currentPresentation.slides;
    }

    return currentPresentation.slides.filter((slide) => slide.branch === currentSlide?.branch);
  }, [currentPresentation, currentSlide?.branch]);

  const currentSlideIndex = useMemo(
    () => currentSlides?.findIndex((slide) => slide._id === currentSlideId) ?? 0,
    [currentSlides, currentSlideId],
  );

  const getAppState = useEventHandler(
    () => ({
      userType,
      detailingEnv,
      meet,
      currentSlide,
      currentPresentation,
    }),
    [meet, currentSlide, currentPresentation],
  );

  const trackBridgeEvent = useEventHandler(() => {
    trackEvent({ event: 'js_bridge', payload: {} });
  }, [trackEvent]);

  usePublicCtmSdk({ getAppState, trackEvent: trackBridgeEvent });

  return {
    currentPresentation,
    currentSlideIndex,
    currentSlide,
    currentSlides,
  };
}

export function usePresentControls({
  meet,
  currentPresentation,
  setSlideId,
  setPresentId,
  current,
  currentSlides,
}: {
  meet: Meeting;
  currentPresentation?: CurrentPresentation;
  current: number;
  setSlideId: (id: ID) => void;
  setPresentId: (id: ID) => void;
  currentSlides?: Slide[];
}) {
  const count = currentSlides?.length ?? 0;
  const trackEvent = useAnalytic();

  const goTo = useCallback(
    (slideIndex: number) => {
      const id = currentSlides?.[slideIndex - 1]._id;

      if (id) {
        setSlideId(id);
      }
    },
    [currentSlides, setSlideId],
  );

  const goNext = useCallback(() => {
    if (current < count) {
      goTo(current + 1);
    }

    return true;
  }, [current, count, goTo]);

  const goBack = useCallback(() => {
    if (current > 1) {
      goTo(current - 1);
    }

    return true;
  }, [current, goTo]);

  const getCurrentState = useEventHandler(
    () => ({
      meet,
      currentPresentation,
      currentSlides: currentSlides || [],
    }),
    [currentSlides, meet, currentPresentation],
  );

  type CBType = <T extends (...args: any[]) => any>(
    cb: T,
  ) => (...args: Parameters<T>) => ReturnType<T>;

  const trackableEventHandler = useMemo<CBType>(
    () =>
      (cb) =>
      (...args) => {
        trackEvent({ event: 'js_bridge_slide_view', payload: {} });

        return cb(...args);
      },
    [trackEvent],
  );

  const goToSlide = useEventHandler(trackableEventHandler(goTo), [goTo, trackableEventHandler]);
  const goNextSlide = useEventHandler(trackableEventHandler(goNext), [
    goNext,
    trackableEventHandler,
  ]);
  const goBackSlide = useEventHandler(trackableEventHandler(goBack), [
    goBack,
    trackableEventHandler,
  ]);
  const setSlide = useEventHandler(trackableEventHandler(setSlideId), [
    setSlideId,
    trackableEventHandler,
  ]);

  useDescribePublicAppSdk({
    getCurrentState,
    goToSlide,
    goNextSlide,
    goBackSlide,
    setSlide,
    setPresent: setPresentId,
  });

  return {
    goTo,
    goNext,
    goBack,
  };
}

const noop = (..._args: any[]): Promise<any> => Promise.resolve();
const defaultFullscreen = { isFullscreen: false, toggle: noop, isEnabled: false };

export function useLayoutToolbar({ layoutRef }: { layoutRef: React.RefObject<HTMLDivElement> }) {
  const {
    isFullscreen,
    toggle,
    isEnabled: isFullscreenEnabled,
  } = useFullscreen() || defaultFullscreen;
  const [zoom, setZoom] = useState(1);

  const onFullscreen = useCallback(() => {
    const element = layoutRef?.current;

    if (!element) {
      return;
    }

    return toggle(element);
  }, [toggle, layoutRef]);

  return {
    isFullscreenEnabled,
    isFullscreen,
    onFullscreen,
    zoom,
    setZoom,
  };
}

const hotkeysOptions = {
  filter(e: KeyboardEvent) {
    return (e.target as any)?.nodeName === 'BODY';
  },
};

export function useKeyboardInput({ goNext, goBack }: { goNext: () => void; goBack: () => void }) {
  useHotkeys('left', goBack, hotkeysOptions, [goBack]);
  useHotkeys('right', goNext, hotkeysOptions, [goNext]);
  useHotkeys('up', goBack, hotkeysOptions, [goBack]);
  useHotkeys('down', goNext, hotkeysOptions, [goNext]);
  useHotkeys('space', goNext, hotkeysOptions, [goNext]);
}

export function useVotings({
  cacheKey,
  currentSlideId,
  currentPresentationId,
}: {
  cacheKey: string;
  currentSlideId: ID | null;
  currentPresentationId: ID | null;
}) {
  const rootDispatch = useDispatch<AppDispatch>();
  const trackEvent = useAnalytic();

  const voteTo = useSelector(slideVote(cacheKey, currentSlideId || ''));

  const handleVote = useCallback(
    (type: 'like' | 'dislike' | undefined) => {
      if (!currentSlideId || !currentPresentationId) {
        return;
      }

      if (voteTo) {
        trackEvent({
          event: `un${voteTo}` as 'unlike' | 'undislike',
          presentationId: currentPresentationId,
          slideId: currentSlideId,
          payload: {},
        });
      }

      if (type) {
        trackEvent({
          event: type,
          presentationId: currentPresentationId,
          slideId: currentSlideId,
          payload: {},
        });
      }

      rootDispatch({ type: 'slideVote', cacheKey, slideId: currentSlideId, voteTo: type });
    },
    [rootDispatch, trackEvent, cacheKey, currentSlideId, currentPresentationId, voteTo],
  );

  return {
    voteTo,
    handleVote,
  };
}

export function usePresentAnalytics(
  {
    currentPresentationId,
    currentSlideId,
  }: {
    currentPresentationId: ID | null;
    currentSlideId: ID | null;
  },
  {
    isTrackSlideChange = false,
  }: {
    isTrackSlideChange?: boolean;
  } = {},
) {
  const trackEvent = useAnalytic();
  const prevState = usePrevious(
    isTrackSlideChange ? { currentSlideId, currentPresentationId } : undefined,
  );

  const onFrameClick = useEventHandler(
    (payload: { elementPath: string }) => {
      trackEvent({
        event: 'click',
        presentationId: currentPresentationId || undefined,
        slideId: currentSlideId || undefined,
        payload,
      });
    },
    [trackEvent, currentPresentationId, currentSlideId],
  );

  useEffect(() => {
    if (!isTrackSlideChange || !currentSlideId || !currentPresentationId) {
      return;
    }

    trackEvent({
      event: 'slide_view',
      slideId: currentSlideId,
      presentationId: currentPresentationId,
      payload: {
        fromSlideId: prevState?.currentSlideId || null,
        fromPresentationId: prevState?.currentPresentationId || null,
      },
    });
  }, [
    currentSlideId,
    currentPresentationId,
    prevState?.currentSlideId,
    prevState?.currentPresentationId,
    trackEvent,
    isTrackSlideChange,
  ]);

  return {
    onFrameClick,
  };
}
