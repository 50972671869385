import { ID } from 'store/models';
import useConference from 'hooks/useConference';
import CallAbilities from './CallAbilities';
import UsersList from './UsersList';

import styles from './HeaderConference.module.scss';

interface HeaderConferenceProps {
  isPresenter: boolean;
  isLikes: boolean;
  isSelfPointer: boolean;
  isMeetActions?: boolean;
  setLikes: (val: boolean) => void;
  setSelfPointer: (val: boolean) => void;
  userPointers: Set<ID>;
  setPointer: (val: { userId: ID; value: boolean }) => void;
}

const HeaderConference: React.FC<HeaderConferenceProps> = ({
  isPresenter,
  isLikes,
  setLikes,
  isSelfPointer,
  setSelfPointer,
  userPointers,
  setPointer,
  isMeetActions = true,
}) => {
  const { state } = useConference();

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapperLeft}>
        {isPresenter && (
          <CallAbilities
            className={styles.abilities}
            isLikes={isLikes}
            setLikes={setLikes}
            isSelfPointer={isSelfPointer}
            setSelfPointer={setSelfPointer}
          />
        )}
      </div>
      <div className={styles.wrapperRight}>
        <UsersList
          isMeetActions={isMeetActions}
          users={state.users}
          userPointers={userPointers}
          setPointer={setPointer}
        />
      </div>
    </header>
  );
};

export default HeaderConference;
