import { useEffect, useReducer, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'i18n-lite';
import { useSelector } from 'react-redux';

import { joinSDRoom } from 'api';
import { ID } from 'store/models';
import { getLoggedIn } from 'store/selectors';
import { reducer, initialState } from 'hooks/meetReducer';
import { Provider as AnalyticProvider } from 'hooks/analytic';
import useAwake from 'hooks/useAwake';
import Spinner from 'ui/Spinner';
import SelfDetailingLayout from 'components/Present/SelfDetailingLayout';
import LoginLayout from 'components/LoginLayout';
import PublicSdkProvider from 'sdk/Provider';

import textStyles from 'ui/Text.module.scss';
import styles from '../components/PresentLayout.module.scss';

const SelfDetailing: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isLoggedIn = useSelector(getLoggedIn);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sessionId, setSesstionId] = useState<ID>();
  const { t } = useTranslation('selfDetailingPage');
  const analyticsPayload = useMemo(
    () => ({
      meetId: state?.meet?._id ?? '', // ureal case, payload used only for analytics provider
      sessionId: !isLoggedIn ? sessionId : undefined,
    }),
    [state?.meet?._id, sessionId, isLoggedIn],
  );

  useAwake();

  useEffect(() => {
    joinSDRoom({ room: id })
      .then(({ meet, meta }) => {
        setSesstionId(meta.sessionId);

        dispatch({
          type: 'initMeet',
          payload: {
            meet,
          },
        });
      })
      .catch((err) => {
        switch (err.status) {
          case 404: {
            dispatch({ type: 'error', status: '404' });
            break;
          }

          default: {
            dispatch({ type: 'error', status: 'unhandled' });
          }
        }
      });
  }, [id, dispatch]);

  if (state.error) {
    return (
      <LoginLayout isBackButton isLogo>
        <div className={textStyles.center}>
          <h2>{t(`errors.${state.error}`)}</h2>
        </div>
      </LoginLayout>
    );
  }

  if (!state.meet) {
    return <Spinner isCenter />;
  }

  return (
    <AnalyticProvider payload={analyticsPayload}>
      <PublicSdkProvider>
        <div className={styles.layout}>
          <main className={styles.present}>
            <SelfDetailingLayout
              meet={state.meet}
              currentSlideId={state.currentSlideId}
              currentPresentationId={state.currentPresentationId}
              setSlideId={(id) => dispatch({ type: 'setSlideId', payload: { id } })}
              setPresentId={(id) => dispatch({ type: 'setPresentId', payload: { id } })}
            />
          </main>
        </div>
      </PublicSdkProvider>
    </AnalyticProvider>
  );
};

export default SelfDetailing;
