import Router from 'Router';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as I18nProvider } from 'i18n-lite';

import i18n from 'i18n';
import ErrorBoundary from './ErrorBoundary';
import Notifications from './components/Notifications';
import { PortalTarget } from 'ui/hooks';

import store, { persistor } from 'store';

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate persistor={persistor}>
        <I18nProvider i18n={i18n}>
          <ErrorBoundary>
            <Router />

            <Notifications />
            <PortalTarget />
          </ErrorBoundary>
        </I18nProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
