import { useMemo, useContext, useEffect, useState, useRef, useReducer, useCallback } from 'react';
import { useSubscription } from 'use-subscription';

import { reducer, initState } from './reducer';
import {
  MediaTrack,
  MediaTrackType,
  JitsiUser,
  JitsiRoom,
  JitsiConnection,
  UserTracksCollection,
  LocalStats,
  RemoteStats,
} from './types';
import Provider, { RoomContext } from './Provider';
import { useAnalytic } from '../analytic';
import { setupPlayHandlers, dropPlayHandlers } from '../audio';
import { log, isTraceJitsi } from 'utils/logger';
import { isFF } from 'utils/platform';

const { JitsiMeetJS } = window as any;

export { JitsiMeetJS };

const { REACT_APP_JITSI_SERVICE_URL, REACT_APP_JITSI_APP_ID, REACT_APP_JITSI_CLIENT_NODE } =
  process.env;

const getTracksConfig = (type: 'video' | 'audio' | 'desktop') =>
  ({
    resolution: type === 'desktop' ? 1080 : 240,
    desktopSharingFrameRate: {
      min: 24,
      max: 30,
    },
    constraints: {
      video: {
        aspectRatio: 16 / 9,
        height: {
          ideal: 720,
          max: 1080,
          min: 240,
        },
      },
    },
  } as const);

let isInited = false;

function initJitsi() {
  if (!isInited) {
    JitsiMeetJS.init({
      disableAudioLevels: false,
      disableSimulcast: false,
    });

    JitsiMeetJS.setLogLevel(
      isTraceJitsi ? JitsiMeetJS.logLevels.TRACE : JitsiMeetJS.logLevels.WARN,
    );

    isInited = true;
  }
}

function useMediaDevice(
  type: MediaTrackType,
  addTrack: (track: MediaTrack) => void,
  removeTrack: (trackType: MediaTrackType) => void,
  device?: 'video' | 'audio' | 'desktop',
  currentTrack?: MediaTrack,
  isMutable: boolean = false,
) {
  const trackEvent = useAnalytic();

  return useCallback(
    (active: boolean = true, mediaDeviceId?: string) => {
      if (
        isMutable &&
        currentTrack &&
        (mediaDeviceId === '' || // default safari behavior
          currentTrack?.deviceId === mediaDeviceId)
      ) {
        return active ? currentTrack.unmute() : currentTrack.mute();
      }

      if (!active) {
        removeTrack(type);

        return Promise.resolve();
      }

      const trackDevice = device ?? type;

      return Promise.all([
        currentTrack && isFF ? currentTrack.dispose() : Promise.resolve(),
        JitsiMeetJS.createLocalTracks({
          devices: [trackDevice],
          [type === 'video' ? 'cameraDeviceId' : 'micDeviceId']: mediaDeviceId,
          ...getTracksConfig(trackDevice),
        }),
      ]).then(([, tracks]: [any, MediaTrack[]]) => {
        console.log('tracks getted', tracks);

        tracks.forEach((track) => {
          addTrack(track);
        });

        trackEvent({ event: trackDevice, payload: {} });
      });
    },
    [type, device, addTrack, removeTrack, trackEvent, isMutable, currentTrack],
  );
}

function useTrackInRoom(
  room: JitsiRoom | null,
  removeTrack: (trackType: MediaTrackType) => void,
  track?: MediaTrack,
) {
  useEffect(() => {
    if (room === null || !track || track.disposed) {
      return;
    }

    const localTracks = room.getLocalTracks();
    const currentLocalTrack = localTracks.find((localTrack) => track.type === localTrack.type);
    const onStopped = () => {
      removeTrack(track.type);
    };

    track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, onStopped);

    (currentLocalTrack && !currentLocalTrack.disposed
      ? room.replaceTrack(currentLocalTrack, track)
      : room.addTrack(track)
    ).then(() => {
      if (track?.videoType === 'desktop') {
        room.setSenderVideoConstraint(1080);
      }
      console.log('track in room added');
    });

    const tracksCache = track;

    return () => {
      tracksCache.removeEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, onStopped);

      if (tracksCache.disposed) {
        return;
      }

      tracksCache.dispose().then(() => {
        console.log('track disposed');
      });
    };
  }, [room, track, removeTrack]);
}

export default function useJitsi({ roomId: id, token }: { roomId: string; token?: string | null }) {
  const connectionRef = useRef<null | JitsiConnection>(null);
  const [roomState, setRoomState] = useState<null | JitsiRoom>(null);
  const [state, dispatch] = useReducer(reducer, initState);
  const [connectionState, setConnectionState] = useState<'pending' | 'error' | 'ready'>('pending');

  useEffect(() => {
    if (!token) {
      return;
    }

    initJitsi();

    const connection: JitsiConnection = new JitsiMeetJS.JitsiConnection(
      REACT_APP_JITSI_APP_ID,
      token,
      {
        serviceUrl: `${REACT_APP_JITSI_SERVICE_URL}?room=${id}`,
        clientNode: REACT_APP_JITSI_CLIENT_NODE,
        websocketKeepAlive: -1,
        hosts: {
          domain: 'meet.jitsi',
          muc: 'muc.meet.jitsi',
        },
        disableBeforeUnloadHandlers: true,
      },
    );

    const onRemoteTrack = (track: MediaTrack) => {
      if (track.isLocal()) {
        return;
      }

      const jitId = track.getParticipantId();

      if (track?.videoType === 'desktop') {
        room?.selectParticipant(jitId);
        room?.setReceiverVideoConstraint(1080);
      }

      dispatch({
        type: 'onAddTrack',
        payload: {
          jitId,
          track,
        },
      });

      console.log('track added', track);
    };

    const onRemoveTrack = (track: MediaTrack) => {
      const jitId = track.getParticipantId();

      dispatch({
        type: 'onRemoveTrack',
        payload: {
          jitId,
          trackType: track.type,
        },
      });

      console.log('track removed', track);
    };

    const onLocalStats = (stats: LocalStats) => {
      if (process.env.NODE_ENV !== 'development') {
        log({ jitId: 'currentUser', stats });
      }
    };

    const onRemoteStats = (jitId: string, stats: RemoteStats) => {
      if (process.env.NODE_ENV !== 'development') {
        log({ jitId, stats });
      }
    };

    const onConferenceJoined = () => {
      setConnectionState('ready');
    };

    const onConnectionFailed = () => {
      setConnectionState('error');
    };

    const disconnect = () => {
      setConnectionState('pending');
      console.log('disconnect');
    };

    const onUserLeft = (jitId: string) => {
      dispatch({ type: 'removeUser', payload: { jitId } });
    };

    const onUserJoined = (id: string, jitUser: JitsiUser) => {
      console.log(id, jitUser);
      dispatch({
        type: 'onUser',
        payload: {
          jitId: jitUser.getId(),
          userId: jitUser._identity.user.id,
        },
      });
    };

    let room: null | JitsiRoom = null;

    const onConnectionSuccess = () => {
      console.log('onConnectionSuccess');

      room = connection.initJitsiConference(id, {
        // confID: id,
        startSilent: true,
        // forceJVB121Ratio: 1,
        forceJVB121Ratio: -1,
        enableLayerSuspension: true,
        e2eping: {
          pingInterval: -1,
        },
        p2p: {
          enabled: false,
        },
      });
      room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
      room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoveTrack);
      room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
      room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
      room.on(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
      room.on(JitsiMeetJS.events.connection.CONNECTION_ERROR, disconnect);
      room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, onLocalStats);
      room.on(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, onRemoteStats);

      console.log('room', room);

      room.join();

      setRoomState(room);
    };
    console.log('connection', connection);

    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      onConnectionSuccess,
    );
    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      onConnectionFailed,
    );
    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, disconnect);
    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DROPPED_ERROR, disconnect);

    setConnectionState('pending');

    connection.connect();

    connectionRef.current = connection;

    return () => {
      if (room !== null) {
        room.off(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
        room.off(JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoveTrack);
        room.off(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
        room.off(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.off(JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
        room.off(JitsiMeetJS.events.connection.CONNECTION_ERROR, disconnect);
        room.off(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, onLocalStats);
        room.off(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, onRemoteStats);

        room.leave();

        setRoomState(null);
      }

      connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        onConnectionSuccess,
      );
      connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        onConnectionFailed,
      );
      connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        disconnect,
      );
      connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DROPPED_ERROR,
        disconnect,
      );
      connection.disconnect();

      connectionRef.current = null;
    };
  }, [id, token]);

  const addLocalTrack = useCallback((track: MediaTrack) => {
    dispatch({
      type: 'onAddLocalTrack',
      payload: {
        track,
      },
    });
  }, []);

  const removeLocalTrack = useCallback((trackType: MediaTrackType) => {
    dispatch({
      type: 'onRemoveLocalTrack',
      payload: {
        trackType,
      },
    });
  }, []);

  useTrackInRoom(roomState, removeLocalTrack, state.tracks.currentUser?.video);
  useTrackInRoom(roomState, removeLocalTrack, state.tracks.currentUser?.audio);

  const requestAudio = useMediaDevice(
    'audio',
    addLocalTrack,
    removeLocalTrack,
    'audio',
    state.tracks.currentUser?.audio,
    true,
  );

  const requestVideo = useMediaDevice('video', addLocalTrack, removeLocalTrack);
  const requestScreenShare = useMediaDevice('video', addLocalTrack, removeLocalTrack, 'desktop');

  return {
    requestDevices: {
      audio: requestAudio,
      video: requestVideo,
      screenShare: requestScreenShare,
    },
    tracks: state.tracks,
    room: roomState,
    connectionState,
  };
}

export function useVAD(track?: MediaTrack) {
  const [volume, setVolume] = useState(0);

  const isMuted = useMuted(track);

  useEffect(() => {
    if (!track) {
      return;
    }

    const onLevelChange = (level: number) => {
      setVolume(Math.floor(level * 100));
    };

    track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, onLevelChange);

    return () => {
      track.removeEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, onLevelChange);
    };
  }, [track]);

  return isMuted ? null : volume;
}

export function useRenderTrack(elRef: React.RefObject<HTMLMediaElement>, track?: MediaTrack) {
  useEffect(() => {
    const { current: el } = elRef;

    if (!track || !el) {
      return;
    }

    console.log('render track', track.type);

    track.attach(el);

    const handlers = setupPlayHandlers(el);

    return () => {
      dropPlayHandlers(el, handlers);

      track.detach(el);
    };
  }, [elRef, track]);
}

interface DeviceList {
  audioinput: InputDeviceInfo[];
  videoinput: InputDeviceInfo[];
  audiooutput: MediaDeviceInfo[];
}

export function useDevices() {
  const [devices, setDevices] = useState<DeviceList>({
    audioinput: [],
    videoinput: [],
    audiooutput: [],
  });

  const [currentAudio, setCurrentAudio] = useState<InputDeviceInfo>();
  const [currentVideo, setCurrentVideo] = useState<InputDeviceInfo>();

  useEffect(() => {
    initJitsi();

    if (!('mediaDevices' in navigator)) {
      console.error('useDevices: mediaDevices not supported on this platform');

      return;
    }

    const onListChange = (list: Array<InputDeviceInfo | MediaDeviceInfo>) => {
      const deviceList: DeviceList = list.reduce(
        (acc: DeviceList, item) => {
          acc[item.kind].push(item as any);

          return acc;
        },
        {
          audioinput: [],
          videoinput: [],
          audiooutput: [],
        },
      );

      setDevices(deviceList);

      return deviceList;
    };

    JitsiMeetJS.mediaDevices.enumerateDevices((list: Array<InputDeviceInfo | MediaDeviceInfo>) => {
      const parsedList = onListChange(list);

      setCurrentAudio(parsedList.audioinput?.[0]);
      setCurrentVideo(parsedList.videoinput?.[0]);
    });

    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      onListChange,
    );

    return () => {
      JitsiMeetJS.mediaDevices.removeEventListener(
        JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
        onListChange,
      );
    };
  }, []);

  return {
    devices,
    currentAudio,
    setCurrentAudio,
    currentVideo,
    setCurrentVideo,
  };
}

export function useScreenShareEnabled() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    initJitsi();
    setIsActive(JitsiMeetJS.isDesktopSharingEnabled());
  }, []);

  return isActive;
}

export function useMuted(track?: MediaTrack) {
  const subscription = useMemo(
    () => ({
      getCurrentValue: () => (track ? track.isMuted() : true),
      subscribe: (callback: () => any) => {
        if (!track) {
          return () => {};
        }

        track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, callback);

        return () =>
          track.removeEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, callback);
      },
    }),
    [track],
  );

  return useSubscription(subscription);
}

export function useParseTracks(tracks: UserTracksCollection, focusUserId: string | null) {
  const isAudio = !useMuted(tracks.currentUser?.audio);

  return useMemo(() => {
    const conference = !focusUserId
      ? tracks
      : {
          ...tracks,
          [focusUserId]: {
            ...(tracks[focusUserId] || {}),
            video: undefined,
          },
        };
    const screenShare = !focusUserId ? undefined : tracks?.[focusUserId]?.video;

    return {
      conference,
      screenShare,
      activeDevices: {
        isAudio,
        isVideo: tracks.currentUser.video?.videoType === 'camera',
        isScreenShare: tracks.currentUser.video?.videoType === 'desktop',
      },
    };
  }, [tracks, isAudio, focusUserId]);
}

export function useRoom() {
  return useContext(RoomContext);
}

export { Provider };
