import { useMemo, useState, useEffect } from 'react';

import { useDescribeWebViewSdk, wvSdk } from 'sdk';
import useEventHandler from 'hooks/useEventHandler';
import { useEvent } from 'hooks/socket';
import { Message, Meeting, User, ID } from 'store/models';

interface WebViewConferenceProps {
  meet: Meeting;
  onFinish: () => void;
  onMessage: (e: { text: string }) => any;
  isSelfPointer: boolean;
  setSelfPointer: (value: boolean) => any;
  setLikes: (val: boolean) => void;
  isRecord: boolean;
  setIsRecord: (value: boolean) => any;
  userPointers: Set<ID>;
  setPointer: ({ userId, value }: { userId: ID; value: boolean }) => void;
}

export default function WebViewConference({
  meet,
  isSelfPointer,
  isRecord,
  setLikes,
  setPointer,
  userPointers,
  setSelfPointer,
  setIsRecord,
  onFinish: exitRoom,
  onMessage: sendMessage,
}: WebViewConferenceProps) {
  const [users, setUsers] = useState<User[]>([]);
  const presenterId = useMemo(() => meet.presenter?._id ?? '', [meet.presenter?._id]);

  const getRoomInfo: typeof wvSdk['_app']['getRoomInfo'] = useEventHandler(() => {
    return {
      roomUI: true,
      status: meet.finishedAt ? 'ended' : 'in_progress',
      isLikes: meet.isLikes,
      isPointer: isSelfPointer,
      isRecord,
    };
  }, [meet.finishedAt, meet.isLikes, isSelfPointer, isRecord]);

  const handleSetGuestPointer = useEventHandler(
    ({ value, id }: { value: boolean; id: ID }) => {
      setPointer({ userId: id, value });
    },
    [setPointer],
  );

  useDescribeWebViewSdk({
    exitRoom,
    sendMessage,
    getRoomInfo,
    setLikes,
    setPointer: setSelfPointer,
    setGuestPointer: handleSetGuestPointer,
    setRecording: setIsRecord,
  });

  useEvent('chat-message', ({ message }: { message: Message }) => {
    wvSdk.gotMessage(message);
  });

  useEvent('conference-info', ({ users, messages }: { users: User[]; messages: Message[] }) => {
    setUsers(users);
    wvSdk.refreshUserList(users, presenterId, userPointers);
    messages.forEach((message) => {
      wvSdk.gotMessage(message);
    });
  });

  useEvent('conference-enter', ({ user }: { user: User }) => {
    if (!users.some((u) => u._id === user._id)) {
      const newUsers = [...users, user];

      setUsers(newUsers);
      wvSdk.refreshUserList(newUsers, presenterId, userPointers);
    }
  });

  useEvent('conference-leave', ({ user }: { user: User }) => {
    if (users.some((u) => u._id === user._id)) {
      const newUsers = users.filter((u) => u._id === user._id);

      setUsers(newUsers);
      wvSdk.refreshUserList(newUsers, presenterId, userPointers);
    }
  });

  useEffect(() => {
    wvSdk.setRoomUi(true);

    return () => {
      wvSdk.setRoomUi(false);
    };
  }, []);

  return null;
}
