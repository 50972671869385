import { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation, Trans } from 'i18n-lite';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Provider as SocketProvider } from 'hooks/socket';
import { Provider as AnalyticProvider } from 'hooks/analytic';

import { joinRoom } from 'api';
import LoginLayout from 'components/LoginLayout';
import { AppDispatch } from 'store';
import { getCurrentUserName } from 'store/selectors';
import { reducer, initialState } from 'hooks/meetReducer';
import PresentLayout from 'components/PresentLayout';
import Spinner from 'ui/Spinner';
import { prevent } from 'utils/events';
import { wvSdk } from 'sdk';

import textStyles from 'ui/Text.module.scss';
import { Meeting, ID, MeetingInfo } from 'store/models';

const { REACT_APP_SOCKET_ENDPOINT } = process.env;

interface PresentViewProps {
  token: string;
  roomId: string;
  isConferenceOnly?: boolean;
  isPresentOnly?: boolean;
  isEmbedUI?: boolean;
}

const PresentView: React.FC<PresentViewProps> = ({
  token,
  isConferenceOnly = false,
  isPresentOnly = false,
  isEmbedUI = false,
  roomId,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const { t } = useTranslation('presentationPage');
  const { meet, currentSlideId, currentPresentationId, userPointers } = state;
  const [mediaToken, setMediaToken] = useState<string | null>(null);
  const query = useMemo(
    () => ({
      roomId: meet?._id,
    }),
    [meet?._id],
  );
  const rootDispatch = useDispatch<AppDispatch>();
  const currentUserName = useSelector(getCurrentUserName);

  useEffect(() => {
    joinRoom({ room: roomId })
      .then((res) => {
        dispatch({
          type: 'initMeet',
          payload: {
            meet: res.meet,
          },
        });

        const { token } = res.mediaMeta;

        wvSdk.settings(
          {
            meetId: res.meet.meetId,
            mediaToken: token,
            username: currentUserName || '',
            isLikes: res.meet.isLikes,
            // Front-end state only
            isPointer: false,
            // TODO: pass record state (come from conference-info)
            isRecord: false,
          },
          res.meet.config,
        );

        setMediaToken(token);
      })
      .catch((err) => {
        switch (err.status) {
          case 404: {
            dispatch({ type: 'error', status: '404' });
            break;
          }

          case 403: {
            dispatch({ type: 'error', status: err?.payload?.errorCode ?? 'unhandled' });
            break;
          }

          default: {
            dispatch({ type: 'error', status: 'unhandled' });
          }
        }
      });
  }, [roomId, rootDispatch, history, currentUserName]);

  const actions = useMemo(
    () => ({
      onSetSlide: (id: ID) => dispatch({ type: 'setSlideId', payload: { id } }),
      onSetPresent: (id: ID) => dispatch({ type: 'setPresentId', payload: { id } }),
      onPresentInfo: (id: ID, slideId: ID) =>
        dispatch({ type: 'setPresentId', payload: { id, slideId } }),
      onUpdateMeet: (value: Meeting) => dispatch({ type: 'meetUpdate', payload: { meet: value } }),
      onUpdateMeetInfo: (value: MeetingInfo) =>
        dispatch({ type: 'meetInfoUpdate', payload: { meetInfo: value } }),
      onSetLikes: (value: boolean) => dispatch({ type: 'setLikes', payload: { value } }),
      onSetPointer: ({ userId, value }: { userId: ID; value: boolean }) =>
        dispatch({ type: 'setPointer', payload: { userId, value } }),
      onExit: () => dispatch({ type: 'setExit', payload: { value: true } }),
      onFinish: () => dispatch({ type: 'onFinish' }),
      setIsRecord: (value: boolean) => dispatch({ type: 'setIsRecording', payload: { value } }),
      setFocusUserId: (id: ID | null) => dispatch({ type: 'setFocusUserId', payload: { id } }),
    }),
    [],
  );

  const events = useMemo(
    () => ({
      'user-session-end': () => dispatch({ type: 'error', status: 'sessionSupplant' }),
    }),
    [dispatch],
  );

  if (state.error) {
    return (
      <LoginLayout isBackButton isLogo>
        <div className={textStyles.center}>
          <h2>{t(`errors.${state.error}`)}</h2>
        </div>
      </LoginLayout>
    );
  }

  // TODO: add check for finishedAt > Date.now
  if (meet?.finishedAt) {
    return (
      <LoginLayout isBackButton={meet.config.isCallRepeat} isLogo customLogo={meet.orgLogo}>
        <div className={textStyles.center}>
          <h2>{t('errors.meetEnd')}</h2>
        </div>
      </LoginLayout>
    );
  }

  if (state.isExit) {
    return (
      <LoginLayout isBackButton isLogo customLogo={meet?.orgLogo}>
        <div className={textStyles.center}>
          <h2>{t('errors.meetExit')}</h2>
          <Trans
            t={t}
            i18nKey="errors.meetExitLink"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="#back"
                  className={textStyles.link}
                  onClick={prevent(() => dispatch({ type: 'setExit', payload: { value: false } }))}
                />
              ),
            }}
          />
        </div>
      </LoginLayout>
    );
  }

  if (mediaToken === null || !query.roomId || !meet) {
    return <Spinner isCenter />;
  }

  return (
    <SocketProvider
      url={REACT_APP_SOCKET_ENDPOINT as string}
      query={query as { roomId: string }}
      token={token}
      events={events}
    >
      <AnalyticProvider useSocket>
        <PresentLayout
          meet={meet}
          isRecord={state.isRecording}
          presenterLeaveAt={state.presenterLeaveAt}
          isPresentOnly={isPresentOnly}
          focusUserId={state.focusUserId}
          isConferenceOnly={isConferenceOnly}
          userPointers={userPointers}
          mediaToken={mediaToken}
          currentSlideId={currentSlideId}
          currentPresentationId={currentPresentationId}
          roomId={roomId}
          isVerticalSlidesMap={isEmbedUI}
          isBottomHeader={isEmbedUI}
          isHeaderConf={isEmbedUI}
          {...actions}
        />
      </AnalyticProvider>
    </SocketProvider>
  );
};

export default PresentView;
