import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'i18n-lite';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { createCall } from 'api';
import { AppDispatch } from 'store';
import { getCurrentUser } from 'store/selectors';
import Tooltip from 'ui/Tooltip';
import Button from 'ui/Button';
import { User } from 'store/models';
import { prevent } from 'utils/events';

import styles from './Header.module.scss';

const { REACT_APP_API_ENDPOINT } = process.env;

interface UserMenuProps {
  user: User;
  isCreateMeet?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({ user, isCreateMeet }) => {
  const { t } = useTranslation('userMenu');
  const [isShowUserMenu, setIsShowUserMenu] = useState(false);
  const rootDispatch = useDispatch<AppDispatch>();
  const { push } = useHistory();
  const handleLogOut = useMemo(
    () =>
      prevent(() => {
        rootDispatch({ type: 'logout' });
      }),
    [rootDispatch],
  );

  const handleMeetCreate = useMemo(
    () =>
      prevent(async () => {
        try {
          const { meet } = await createCall();

          push(`/c/${meet.meetId}`);
        } catch (err: any) {
          console.log(err);
        }
      }),
    [push],
  );

  return (
    <span>
      <span className={styles.userMenuCaption}>{t('loggedAs')}</span>{' '}
      <Tooltip
        isVisible={isShowUserMenu}
        setVisible={setIsShowUserMenu}
        isOutsideClose
        render={(ref) => (
          <Button ref={ref} size="sm" onClick={() => setIsShowUserMenu((val) => !val)}>
            {user.name}
          </Button>
        )}
        content={
          <ul className={styles.userMenu}>
            {!user.isAnonymous && isCreateMeet && (
              <li>
                <a href="#create-meet" onClick={handleMeetCreate}>
                  {t('createMeet')}
                </a>
              </li>
            )}
            <li>
              <a href="#log-out" onClick={handleLogOut}>
                {t('logOut')}
              </a>
            </li>
          </ul>
        }
      />
    </span>
  );
};

interface HeaderProps {
  isSfLogin?: boolean;
  entityType?: string;
  entityId?: string;
  isMsLogin?: boolean;
  isCreateMeet?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  isSfLogin = true,
  isMsLogin = false,
  isCreateMeet = true,
  entityType,
  entityId,
}) => {
  const { t } = useTranslation('joinPage');
  const currentUser = useSelector(getCurrentUser);

  const msLoginUrl = useMemo(() => {
    let href = `${REACT_APP_API_ENDPOINT}/users/ms?`;

    href += `cbUrl=${window.location.origin}${window.location.pathname}`;

    return href;
  }, []);

  const sfLoginUrl = useMemo(() => {
    let href = `${REACT_APP_API_ENDPOINT}/users/sf?`;

    if (entityType) {
      href += `entityType=${entityType}&entityId=${entityId}&`;
    }

    href += `cbUrl=${window.location.origin}${window.location.pathname}`;

    return href;
  }, [entityType, entityId]);

  return (
    <header className={styles.heading}>
      {currentUser ? (
        <UserMenu user={currentUser} isCreateMeet={isCreateMeet} />
      ) : (
        <>
          {isMsLogin && (
            <Button color="theme" size="sm" onClick={() => (window.location.href = msLoginUrl)}>
              {t(':userMenu.loginMs')}
            </Button>
          )}
          {isSfLogin && (
            <Button size="sm" onClick={() => (window.location.href = sfLoginUrl)}>
              <Trans i18nKey="loginButton" t={t} components={{ b: <b /> }} />
            </Button>
          )}
        </>
      )}
    </header>
  );
};

export default Header;
