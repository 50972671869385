import last from 'lodash/last';
import { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'i18n-lite';

import { useEvent } from 'hooks/socket';
import { AppDispatch } from 'store';
import { MeetingInfo, Message, User, ID } from 'store/models';

type Tabs = 'conference' | 'comments';

const initialState: {
  activeTab: Tabs;
  lastReadMessage: string | null;
  messages: Message[];
  users: User[];
  mutedUsers: Set<ID>;
} = {
  activeTab: 'conference',
  lastReadMessage: null,
  mutedUsers: new Set(),
  messages: [],
  users: [],
};

type Actions =
  | {
      type: 'conferenceInfo';
      payload: {
        users: User[];
        messages: Message[];
      };
    }
  | {
      type: 'updateMuteList';
      payload: {
        mutedUsers: ID[];
      };
    }
  | {
      type: 'setMuted';
      payload: {
        userId: ID;
        value: boolean;
      };
    }
  | {
      type: 'userEnter';
      payload: {
        user: User;
      };
    }
  | {
      type: 'userLeave';
      payload: {
        _id: string;
      };
    }
  | {
      type: 'newMessage';
      payload: {
        message: Message;
      };
    }
  | {
      type: 'setActiveTab';
      payload: {
        tab: Tabs;
      };
    };

function reducer(state: typeof initialState, action: Actions): typeof initialState {
  switch (action.type) {
    case 'conferenceInfo': {
      const lastReadMessage = last(action.payload?.messages)?._id ?? null;

      return {
        ...state,
        ...action.payload,
        lastReadMessage,
      };
    }

    case 'userEnter':
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };

    case 'userLeave':
      return {
        ...state,
        users: state.users.filter(({ _id }) => _id !== action.payload._id),
      };

    case 'newMessage': {
      const { message } = action.payload;
      const newState = {
        ...state,
        messages: [...state.messages, message],
      };

      if (state.activeTab === 'comments') {
        newState.lastReadMessage = message._id;
      }

      return newState;
    }

    case 'updateMuteList': {
      return {
        ...state,
        mutedUsers: new Set(action.payload.mutedUsers),
      };
    }

    case 'setMuted': {
      const mutedUsers = new Set(state.mutedUsers);

      mutedUsers[action.payload.value ? 'add' : 'delete'](action.payload.userId);

      return {
        ...state,
        mutedUsers,
      };
    }

    case 'setActiveTab': {
      const { tab } = action.payload;
      const newState = {
        ...state,
        activeTab: tab,
      };
      if (tab !== 'comments') {
        newState.lastReadMessage = last(newState.messages)?._id ?? null;
      }

      return newState;
    }

    default:
      return state;
  }
}

export default function useConference() {
  const rootDispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation('notifications');
  const [state, dispatch] = useReducer(reducer, initialState);

  useEvent('meet-info', ({ meetInfo }: { meetInfo: MeetingInfo }) => {
    dispatch({ type: 'updateMuteList', payload: { mutedUsers: meetInfo.mutedUsers } });
  });

  useEvent('conference-info', ({ users, messages }: { users: User[]; messages: Message[] }) => {
    dispatch({ type: 'conferenceInfo', payload: { users, messages } });
  });

  useEvent('conference-enter', ({ user }: { user: User }) => {
    dispatch({ type: 'userEnter', payload: { user } });

    rootDispatch({
      type: 'addNotifcation',
      notificaiton: {
        title: (
          <Trans
            t={t}
            i18nKey="userJoin.title"
            components={{ b: <b /> }}
            values={{ user: user.name }}
          />
        ),
      },
    });
  });

  useEvent('conference-leave', ({ user }: { user: User }) => {
    dispatch({ type: 'userLeave', payload: { _id: user._id } });

    rootDispatch({
      type: 'addNotifcation',
      notificaiton: {
        title: (
          <Trans
            t={t}
            i18nKey="userLeave.title"
            components={{ b: <b /> }}
            values={{ user: user.name }}
          />
        ),
      },
    });
  });

  return {
    state,
    dispatch,
  };
}
