import { useTranslation } from 'i18n-lite';
import cn from 'classnames';

import Button from 'ui/Button';

import { ReactComponent as LikeOnIcon } from 'assets/LikeOnIcon.svg';
import { ReactComponent as LikeOffIcon } from 'assets/LikeOffIcon.svg';

import { ReactComponent as PointerOnIcon } from 'assets/PointerOnIcon.svg';
import { ReactComponent as PointerOffIcon } from 'assets/PointerOffIcon.svg';

import styles from './Conference.module.scss';

interface CallAbilitiesProps {
  isLikes: boolean;
  isSelfPointer: boolean;
  setSelfPointer: (val: boolean) => void;
  setLikes: (val: boolean) => void;
  className?: string;
}

const CallAbilities: React.FC<CallAbilitiesProps> = ({
  isLikes,
  isSelfPointer,
  setLikes,
  setSelfPointer,
  className,
}) => {
  const { t } = useTranslation('conference');

  return (
    <div className={cn(styles.abilities, className)}>
      <div className={styles.ability}>
        <Button
          isRounded
          isBordered
          size="sm"
          color={isLikes ? 'theme' : 'simple'}
          onClick={() => setLikes(!isLikes)}
        >
          {isLikes ? <LikeOnIcon /> : <LikeOffIcon />}
        </Button>
        <div className={styles.abilityCaption}>{t(`likes.${isLikes ? 'on' : 'off'}`)}</div>
      </div>
      <div className={styles.ability}>
        <Button
          isRounded
          isBordered
          size="sm"
          color={isSelfPointer ? 'theme' : 'simple'}
          onClick={() => setSelfPointer(!isSelfPointer)}
        >
          {isSelfPointer ? <PointerOnIcon /> : <PointerOffIcon />}
        </Button>
        <div className={styles.abilityCaption}>{t(`pointer.${isSelfPointer ? 'on' : 'off'}`)}</div>
      </div>
    </div>
  );
};

export default CallAbilities;
