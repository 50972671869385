import { useState, useMemo, memo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'i18n-lite';

import { User, ID } from 'store/models';
import { TrackVolume } from './SoundVolume';
import { UserTracks } from 'hooks/jitsi/types';
import colorFromId from 'utils/colorFromId';
import UserAudio from './UserAudio';
import UserVideo from './UserVideo';
import ConnectionState from './ConnectionState';

import styles from './ConferenceUser.module.scss';

import { ReactComponent as MicOnIcon } from 'assets/MicOnIcon.svg';
import { ReactComponent as MicOffIcon } from 'assets/MicOffIcon.svg';
import { ReactComponent as PointerOnIcon } from 'assets/PointerOnIcon.svg';
import { ReactComponent as PointerOffIcon } from 'assets/PointerOffIcon.svg';
import { ReactComponent as DesktopOnIcon } from 'assets/DesktopOnIcon.svg';
import { ReactComponent as DesktopOffIcon } from 'assets/DesktopOffIcon.svg';

interface ConferenceUserProps {
  className?: string;
  user: User;
  tracks?: UserTracks;
  isAudioTrack?: boolean;
  isCurrentUser?: boolean;
  isPresenter?: boolean;
  isMuted: boolean;
  isPointer: boolean;
  isFocused: boolean;
  setPointer: (userId: ID, e: boolean) => any;
  setMuted: (userId: ID, e: boolean) => any;
  setFocus: (userId: ID, e: boolean) => any;
  isMeetActions?: boolean;
}

const ConferenceUser: React.FC<ConferenceUserProps> = ({
  className,
  user,
  tracks,
  isAudioTrack = true,
  isPresenter = false,
  isCurrentUser = false,
  isMeetActions = true,
  isFocused,
  isMuted,
  setMuted,
  isPointer,
  setPointer,
  setFocus,
}) => {
  const { t } = useTranslation('conference');
  const [isFullview, setFullview] = useState(false);
  const userColor = useMemo(() => colorFromId(user._id), [user._id]);
  const bgStyle = useMemo(() => ({ backgroundColor: userColor }), [userColor]);

  const togglePointer = useCallback(() => {
    setPointer(user._id, !isPointer);
  }, [setPointer, isPointer, user._id]);

  const toggleMuted = useCallback(() => {
    setMuted(user._id, !isMuted);
  }, [user._id, isMuted, setMuted]);

  const toggleFocus = useCallback(() => {
    setFocus(user._id, !isFocused);
  }, [setFocus, user._id, isFocused]);

  return (
    <div
      className={cn(
        styles.userRow,
        isFullview && tracks?.video && styles.userRowFullview,
        className,
      )}
    >
      <div className={styles.userPreview}>
        {tracks?.video ? (
          <UserVideo
            className={styles.userVideo}
            onClick={() => setFullview(!isFullview)}
            track={tracks.video}
          />
        ) : (
          user.name[0]
        )}
      </div>
      {isAudioTrack && !isCurrentUser && tracks?.audio && <UserAudio track={tracks.audio} />}
      <div className={styles.userDetails}>
        <div className={styles.userAudioInfo}>
          <TrackVolume
            track={isMuted ? undefined : tracks?.audio}
            className={styles.userAudioInfoMuteIcon}
          />
        </div>
        <div className={styles.userName}>{user.name}</div>
        <span className={styles.userColor} style={bgStyle} />
        <div className={styles.userControl}>
          {isPresenter && isMeetActions && (
            <button
              className={styles.userControlButton}
              onClick={toggleFocus}
              title={t(`focus.${isFocused ? 'on' : 'off'}`)}
            >
              {isFocused ? (
                <DesktopOnIcon className={cn(styles.userControlIcon, styles.userFocusIconActive)} />
              ) : (
                <DesktopOffIcon className={styles.userControlIcon} />
              )}
            </button>
          )}
          {isPresenter && !isCurrentUser && (
            <>
              {isMeetActions && (
                <button
                  className={styles.userControlButton}
                  onClick={togglePointer}
                  title={t(`pointer.${isPointer ? 'on' : 'off'}`)}
                >
                  {isPointer ? (
                    <PointerOnIcon
                      className={cn(styles.userControlIcon, styles.userPointerIconActive)}
                    />
                  ) : (
                    <PointerOffIcon className={styles.userControlIcon} />
                  )}
                </button>
              )}
              <button
                className={styles.userControlButton}
                onClick={toggleMuted}
                title={t(`audio.${!isMuted ? 'on' : 'off'}`)}
              >
                {!isMuted ? (
                  <MicOnIcon className={cn(styles.userControlIcon, styles.userAudioIconActive)} />
                ) : (
                  <MicOffIcon className={styles.userControlIcon} />
                )}
              </button>
            </>
          )}
          {!isPresenter && (
            <>
              {isPointer && (
                <div className={styles.userControlItem} title={t('pointer.on')}>
                  <PointerOnIcon
                    className={cn(styles.userControlIcon, styles.userPointerIconActive)}
                  />
                </div>
              )}
            </>
          )}
          {isCurrentUser && <span className={styles.userSelf}>({t('call.you')})</span>}
        </div>
      </div>
      {!isCurrentUser && <ConnectionState userId={user._id} />}
    </div>
  );
};

export default memo(ConferenceUser);
