import { forwardRef } from 'react';
import { useTranslation } from 'i18n-lite';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { getCurrentUserId } from 'store/selectors';
import { User, ID } from 'store/models';
import Avatar from 'ui/Avatar';

import confStyles from './ConferenceUser.module.scss';
import styles from './UsersList.module.scss';

import { ReactComponent as PointerOnIcon } from 'assets/PointerOnIcon.svg';
import { ReactComponent as PointerOffIcon } from 'assets/PointerOffIcon.svg';

interface ExtendedUserListProps {
  users: User[];
  isMeetActions: boolean;
  userPointers: Set<ID>;
  setPointer: (val: { userId: ID; value: boolean }) => void;
}

const ExtendedUserList = forwardRef<HTMLDivElement, ExtendedUserListProps>(
  ({ users, userPointers, isMeetActions, setPointer }, ref) => {
    const currentUserId = useSelector(getCurrentUserId);
    const { t } = useTranslation('conference');

    return (
      <div ref={ref} className={styles.extendedList}>
        {users.map((user) => {
          const isPointer = userPointers.has(user._id);
          const isCurrentUser = currentUserId === user._id;

          return (
            <div className={styles.userRow} key={user._id}>
              <Avatar className={styles.userRowAvatar} name={user.name} isTooltip={false} />
              <div>
                {user.name}
                {isCurrentUser && <span className={confStyles.userSelf}> ({t('call.you')})</span>}
              </div>
              <div className={styles.userRowActions}>
                {isMeetActions && !isCurrentUser && (
                  <button
                    className={confStyles.userControlButton}
                    onClick={() => setPointer({ userId: user._id, value: !isPointer })}
                    title={t(`pointer.${isPointer ? 'on' : 'off'}`)}
                  >
                    {isPointer ? (
                      <PointerOnIcon
                        className={cn(confStyles.userControlIcon, confStyles.userPointerIconActive)}
                      />
                    ) : (
                      <PointerOffIcon className={confStyles.userControlIcon} />
                    )}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  },
);

export default ExtendedUserList;
