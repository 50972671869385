import { useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppDispatch } from 'store';
import { userJoinViaDirect, userJoinViaService } from 'store/actions';
import { stringyfyQuery } from 'utils/cookie';

export default function useUserLoginViaService(setLoading: (val: boolean) => void) {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  useEffect(() => {
    const query = qs.parse(window.location.search.replace(/^\?/, ''));

    if (query.access_token && query.refresh_token && query.instance_url) {
      setLoading(true);

      dispatch(
        userJoinViaDirect({
          access_token: stringyfyQuery(query.access_token),
          refresh_token: stringyfyQuery(query.refresh_token),
          instance_url: stringyfyQuery(query.instance_url),
        }),
      ).finally(() => {
        setLoading(false);
      });
    }

    if (query.t) {
      setLoading(true);
      const token = stringyfyQuery(query.t);

      dispatch(
        userJoinViaService({
          token,
        }),
      )
        .then(() => {
          history.replace(window.location.pathname);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, history, setLoading]);
}
