import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'i18n-lite';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store';
import { getCurrentUser } from 'store/selectors';
import { prevent } from 'utils/events';
import Button from 'ui/Button';

import LoginLayout from 'components/LoginLayout';

import styles from './Pages.module.scss';
import textStyles from 'ui/Text.module.scss';

interface EnterMeetProps {
  onEnter: () => void;
  isAlwaysBack?: boolean;
}

const EnterMeet: React.FC<EnterMeetProps> = ({ onEnter, isAlwaysBack = true }) => {
  const currentUser = useSelector(getCurrentUser);
  const rootDispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation('joinPage');
  const history = useHistory();

  const handleLogout = useCallback(() => {
    rootDispatch({ type: 'logout' });
  }, [rootDispatch]);

  const isBackButton = useMemo(
    () => isAlwaysBack || history.action !== 'POP',
    [history, isAlwaysBack],
  );

  if (!currentUser) {
    return null;
  }

  let userCreds = currentUser.name;

  if (currentUser.email) {
    userCreds += ` (${currentUser.email})`;
  }

  return (
    <LoginLayout isBackButton={isBackButton}>
      <div>
        <div className={styles.welcome}>
          <h1 className={styles.welcomeHeading}>{t('title')}</h1>
          <p className={styles.welcomeDetails}>
            <Trans
              t={t}
              i18nKey="entering"
              components={{
                b: <b />,
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href="#logout" className={textStyles.link} onClick={prevent(handleLogout)} />
                ),
              }}
              values={{ user: userCreds }}
            />
          </p>
        </div>
      </div>
      <Button isBlock onClick={prevent(onEnter)}>
        {t('submit')}
      </Button>
    </LoginLayout>
  );
};

export default EnterMeet;
