import { useEffect } from 'react';
import { on, off } from 'utils/dom';

export default function useConfirmClose(message: string, isActive = true) {
  useEffect(() => {
    if (!isActive) {
      return;
    }

    const handler = (e: BeforeUnloadEvent) => {
      e = e || window.event;

      e.preventDefault();

      if (e) {
        e.returnValue = message;
      }

      return message;
    };

    on(window, 'beforeunload', handler);

    return () => {
      off(window, 'beforeunload', handler);
    };
  }, [isActive, message]);
}
