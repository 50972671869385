import { useMemo, useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'i18n-lite';
import { JitsiMeetJS, useRoom } from 'hooks/jitsi';
import { LocalStats, RemoteStats, isLocalStats } from 'hooks/jitsi/types';

import Tooltip from 'ui/Tooltip';

import styles from './Conference.module.scss';

interface ConnectionStateProps {
  className?: string;
  connectionState?: 'ready' | 'pending' | 'error';
  userId?: 'currentUser' | string;
}

const StateDescription: React.FC<{ stats?: RemoteStats | LocalStats }> = ({ stats }) => {
  if (!stats) {
    return <div>Stats not collected</div>;
  }

  return (
    <div>
      <div>
        Qlt: {Math.trunc(stats.connectionQuality)}%, RTT: {stats.jvbRTT}, Res:{' '}
        {stats.maxEnabledResolution ?? 0}p
      </div>
      <div>
        <div>Bitrates</div>
        <div>
          Audio: D {stats.bitrate.audio.download} U {stats.bitrate.audio.upload}
        </div>
        <div>
          Video: D {stats.bitrate.video.download} U {stats.bitrate.video.upload}
        </div>
        <div>
          Total: D {stats.bitrate.download} U {stats.bitrate.upload}
        </div>
      </div>
      <div>
        Packet loss: D {stats.packetLoss.download} U {stats.packetLoss.upload} T{' '}
        {stats.packetLoss.total}
      </div>
      {isLocalStats(stats) && (
        <>
          <div>
            Bandwidth: D {stats.bandwidth.download} U {stats.bandwidth.upload}
          </div>
          <div>Bridge: {stats.bridgeCount}</div>
          <div>
            <div>Transports</div>
            {stats.transport.map((trans, index) => (
              <div key={index}>
                {trans.networkType && `Network ${trans.networkType}, `}
                conn: {trans.p2p && 'peer'} {trans.type}@{trans.ip}, rtt: {trans.rtt}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

function useStats(userId?: 'currentUser' | string) {
  const [stats, setStat] = useState<LocalStats | RemoteStats>();
  const room = useRoom();

  useEffect(() => {
    if (!room || !userId) {
      return;
    }

    const onLocalStats = (ev: LocalStats) => {
      setStat({ ...ev });
    };

    const onRemoteStats = (id: string, ev: RemoteStats) => {
      const jitUser = room.participants[id];

      if (jitUser?._identity?.user?.id !== userId) {
        return;
      }

      setStat(ev);
    };

    if (userId === 'currentUser') {
      room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, onLocalStats);
    } else {
      room.on(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, onRemoteStats);
    }

    return () => {
      if (userId === 'currentUser') {
        room.off(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, onLocalStats);
      } else {
        room.off(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, onRemoteStats);
      }
    };
  }, [room, userId]);

  return stats;
}

const ConnectionState: React.FC<ConnectionStateProps> = ({
  className,
  connectionState,
  userId,
}) => {
  const { t } = useTranslation('conference');
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisible = useCallback(() => setIsVisible((val) => !val), []);
  const stats = useStats(userId);

  const state = useMemo(() => {
    if (connectionState) {
      return connectionState;
    }

    const qlt = stats?.connectionQuality ?? null;

    if (qlt === null) {
      return 'await';
    }

    return qlt < 30 ? 'error' : qlt < 70 ? 'pending' : qlt >= 70 && 'ready';
  }, [connectionState, stats?.connectionQuality]);

  return (
    <Tooltip
      className={styles.connectionWrapper}
      isVisible={isVisible}
      placement="bottom-start"
      content={<StateDescription stats={stats} />}
    >
      <div
        onClick={toggleVisible}
        className={cn(styles.connection, styles[`connection--${state}`], className)}
        title={t(`connectionState.${state}`)}
      />
    </Tooltip>
  );
};

export default ConnectionState;
