import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

export type ButtonProps = {
  className?: string;
  isBlock?: boolean;
  isLoading?: boolean;
  isRounded?: boolean;
  isDisabled?: boolean;
  isBordered?: boolean;
  color?: 'primary' | 'secondary' | 'theme' | 'attention' | 'simple' | 'dark';
  size?: 'xs' | 'sm' | 'xsm' | 'md' | 'lg';
  variant?: 'thin';
};

type PlainButtonTags = 'a' | 'button';

type RootButtonTags = PlainButtonTags | React.ElementType;

type ExtendedButtonProps<T extends RootButtonTags> = ButtonProps & {
  component: T;
};

type UIButtonProps<T extends RootButtonTags> = T extends PlainButtonTags
  ? React.ComponentPropsWithoutRef<T> & ExtendedButtonProps<T>
  : React.ComponentProps<T> & ExtendedButtonProps<T>;

type UIButtonElement<T extends RootButtonTags> = T extends PlainButtonTags
  ? HTMLElementTagNameMap[T]
  : T;

function Button<T extends RootButtonTags>(
  {
    component: Component = 'button',
    className,
    isBlock = false,
    isLoading = false,
    isRounded = false,
    isBordered = false,
    variant,
    isDisabled,
    color = 'primary',
    size = 'md',
    ...props
  }: UIButtonProps<T>,
  ref: React.ForwardedRef<UIButtonElement<T>>,
) {
  const elementProps = {
    disabled: isDisabled,
    className: cn(
      styles.button,
      styles[`button--${color}`],
      styles[`button--${size}`],
      variant && styles[`button--${variant}`],
      {
        [styles.isBlock]: isBlock,
        [styles.isRounded]: isRounded,
        [styles.isBordered]: isBordered,
        [styles.isLoading]: isLoading,
      },
      className,
    ),
    ...props,
  };

  switch (Component) {
    case 'a':
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a {...elementProps} ref={ref as React.RefObject<HTMLAnchorElement>} />
      );

    case 'button':
      return <button {...elementProps} ref={ref as React.RefObject<HTMLButtonElement>} />;

    default:
      return <Component {...elementProps} ref={ref} />;
  }
}

const ForwarderRefButton = forwardRef(Button) as <T extends RootButtonTags>( // no way to pass generic to forwardRef
  p: UIButtonProps<T> & { ref?: React.RefObject<UIButtonElement<T>> | null },
) => React.ReactElement;

export default ForwarderRefButton;
