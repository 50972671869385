import { useMemo, useState } from 'react';
import { useTranslation } from 'i18n-lite';
import { useHistory } from 'react-router-dom';

import LoginLayout from 'components/LoginLayout';
import Header from 'components/Header';
import { LabledInput } from 'ui/Input';
import Spinner from 'ui/Spinner';
import Button from 'ui/Button';
import { targetValue, prevent } from 'utils/events';
import useUserLoginViaService from 'hooks/useUserLoginViaService';

import formStyles from 'ui/Form.module.scss';

interface MeetingProps {
  isEmbed?: boolean;
  isLogo?: boolean;
  isCreateMeet?: boolean;
  onSubmit?: (meetId: string) => void;
  submitText?: React.ReactNode;
}

const Meeting: React.FC<MeetingProps> = ({
  isEmbed = false,
  isLogo = true,
  isCreateMeet = true,
  onSubmit,
  submitText,
}) => {
  const { t } = useTranslation('indexPgae');
  const history = useHistory();

  const [meetId, setMeetId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isInputTouched, setInputTouched] = useState(false);
  const handleSubmit = useMemo(
    () =>
      prevent(() => {
        if (onSubmit) {
          return onSubmit(meetId);
        }

        const meetUrl = `${isEmbed ? '/present/embed/' : '/p/'}${meetId}`;

        history.push(meetUrl);
      }),
    [history, meetId, isEmbed, onSubmit],
  );

  useUserLoginViaService(setLoading);

  if (isLoading) {
    return <Spinner isCenter />;
  }

  return (
    <LoginLayout isLogo={isLogo} heading={<Header isCreateMeet={isCreateMeet} isMsLogin />}>
      <form className={formStyles.form}>
        <LabledInput
          tabIndex={1}
          autoFocus
          className={formStyles.formGroup}
          isError={isInputTouched && meetId === ''}
          mask="999-9999-999"
          value={meetId}
          onChange={targetValue(setMeetId)}
          onBlur={() => setInputTouched(true)}
          placeholder={t('meetInput.placeholder')}
          after={t('caption')}
        >
          {t('meetInput.label')}
        </LabledInput>
        <Button isBlock isDisabled={meetId.replace('_', '').length < 12} onClick={handleSubmit}>
          {submitText ? submitText : t('submit')}
        </Button>
      </form>
    </LoginLayout>
  );
};

export default Meeting;
