import { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'i18n-lite';
import { useDispatch } from 'react-redux';

import Button, { ButtonProps } from 'ui/Button';
import Select from 'ui/Select';
import { useDevices } from 'hooks/jitsi';
import { useDescribePublicAppSdk } from 'sdk';
import { AppDispatch } from 'store';

import styles from './DeviceControls.module.scss';

import { ReactComponent as MicOnIcon } from 'assets/MicOnIcon.svg';
import { ReactComponent as MicOffIcon } from 'assets/MicOffIcon.svg';

import { ReactComponent as VideoOnIcon } from 'assets/VideoOnIcon.svg';
import { ReactComponent as VideoOffIcon } from 'assets/VideoOffIcon.svg';

import { ReactComponent as DesktopOnIcon } from 'assets/DesktopOnIcon.svg';
import { ReactComponent as DesktopOffIcon } from 'assets/DesktopOffIcon.svg';

import { ReactComponent as PhoneIcon } from 'assets/PhoneIcon.svg';
import { ReactComponent as CarpetIcon } from 'assets/CarpetIcon.svg';

interface DeviceControlProps {
  type: 'video' | 'audio' | 'share' | 'record';
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  isActive: boolean;
  setActive: (e: boolean, id?: string) => Promise<any>;
  deviceList?: InputDeviceInfo[];
  activeDevice?: InputDeviceInfo;
  setActiveDevice?: (e: InputDeviceInfo) => void;
  render?: (e: { isActive: boolean }) => React.ReactNode;
  btnClassName?: string;
}

const DeviceControl: React.FC<DeviceControlProps> = ({
  type,
  size = 'lg',
  color,
  render,
  children,
  isActive,
  setActive,
  deviceList,
  activeDevice,
  setActiveDevice,
  btnClassName,
}) => {
  const { t } = useTranslation('conference');
  const rootDispatch = useDispatch<AppDispatch>();

  const toggleDevice = useCallback(async () => {
    try {
      await setActive(!isActive, activeDevice?.deviceId);
    } catch (err) {
      rootDispatch({
        type: 'addNotifcation',
        notificaiton: {
          title: t(`errors.${type}`),
          sound: null,
          timeout: 0,
        },
      });
    }
  }, [setActive, isActive, activeDevice?.deviceId, rootDispatch, t, type]);

  useDescribePublicAppSdk({
    [`${type}Toggle`]: toggleDevice,
  });

  return (
    <div className={styles.control}>
      <Button
        className={btnClassName}
        isRounded
        isBordered
        size={size}
        color={color ?? (isActive ? 'theme' : 'simple')}
        onClick={toggleDevice}
      >
        {render ? render({ isActive }) : children}
      </Button>
      {deviceList && (
        <Select
          isNowrap
          className={styles.controlList}
          list={deviceList}
          value={activeDevice}
          onChange={(device) => {
            setActiveDevice && setActiveDevice(device);

            if (isActive) {
              setActive(true, device.deviceId);
            }
          }}
          getItem={(item, index) => item.label || t('defaultMediaDevice', { num: index + 1 })}
          getId={(item) => item.deviceId}
          render={({ isOpen, onOpen }) => (
            <Button isRounded isBordered size="xs" color="simple" onClick={() => onOpen(!isOpen)}>
              <CarpetIcon
                className={cn(
                  styles.controlListTriggerIcon,
                  isOpen && styles.controlListTriggerIconActive,
                )}
              />
            </Button>
          )}
        />
      )}
    </div>
  );
};

interface DeviceControlsProps {
  isVideoAble?: boolean;
  isScreenShareAble?: boolean;
  isRecordable?: boolean;
  isAudio: boolean;
  isRecord: boolean;
  isVideo: boolean;
  isScreenShare?: boolean;
  setRecord: (val: boolean) => any;
  setAudio: (val: boolean, id?: string) => Promise<any>;
  setVideo: (val: boolean, id?: string) => Promise<any>;
  setScreenShare?: (val: boolean) => any;
  onEnd: () => any;
}

const DeviceControls: React.FC<DeviceControlsProps> = ({
  isVideoAble = true,
  isRecordable = false,
  isScreenShareAble = false,
  isAudio,
  isRecord,
  setAudio,
  isVideo,
  setVideo,
  setRecord,
  isScreenShare = false,
  setScreenShare = () => {},
  onEnd,
}) => {
  const { t } = useTranslation('conference');
  const { devices, currentVideo, setCurrentVideo, currentAudio, setCurrentAudio } = useDevices();

  const buttonSize = isRecordable && isScreenShareAble ? 'md' : 'lg';

  return (
    <div
      className={cn(
        styles.deviceControls,
        buttonSize === 'md' && styles.deviceControlsSmallCaptions,
      )}
    >
      {isRecordable && (
        <div className={styles.deviceControl}>
          <DeviceControl
            size={buttonSize}
            type="audio"
            color="simple"
            isActive={isRecord}
            setActive={setRecord}
            btnClassName={cn(styles.recordButton, isRecord && styles.isRecording)}
          />
          <div className={styles.deviceControlCaption}>
            {t(`record.${isRecord ? 'on' : 'off'}`)}
          </div>
        </div>
      )}
      <div className={styles.deviceControl}>
        <DeviceControl
          size={buttonSize}
          type="audio"
          isActive={isAudio}
          setActive={setAudio}
          deviceList={!devices.audioinput.length ? undefined : devices.audioinput}
          activeDevice={currentAudio}
          setActiveDevice={setCurrentAudio}
          render={({ isActive }) => (isActive ? <MicOnIcon /> : <MicOffIcon />)}
        />
        <div className={styles.deviceControlCaption}>{t(`audio.${isAudio ? 'on' : 'off'}`)}</div>
      </div>
      <div className={styles.deviceControl}>
        <Button isRounded isBordered size={buttonSize} color="attention" onClick={() => onEnd()}>
          <PhoneIcon />
        </Button>
        <div className={styles.deviceControlCaption}>{t('endCall')}</div>
      </div>
      {isVideoAble && (
        <div className={styles.deviceControl}>
          <DeviceControl
            size={buttonSize}
            type="video"
            isActive={isVideo}
            setActive={setVideo}
            deviceList={!devices.videoinput.length ? undefined : devices.videoinput}
            activeDevice={currentVideo}
            setActiveDevice={setCurrentVideo}
            render={({ isActive }) => (isActive ? <VideoOnIcon /> : <VideoOffIcon />)}
          />
          <div className={styles.deviceControlCaption}>{t(`video.${isVideo ? 'on' : 'off'}`)}</div>
        </div>
      )}
      {isScreenShareAble && (
        <div className={styles.deviceControl}>
          <DeviceControl
            size={buttonSize}
            type="share"
            isActive={isScreenShare}
            setActive={setScreenShare}
            render={({ isActive }) => (isActive ? <DesktopOnIcon /> : <DesktopOffIcon />)}
          />
          <div className={styles.deviceControlCaption}>
            {t(`screen_share.${isScreenShare ? 'on' : 'off'}`)}
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceControls;
