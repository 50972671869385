type HandlerElementSelector = Element | Document | Window;

export const on = (
  element: HandlerElementSelector,
  event: string | string[],
  handler: (...args: any) => any,
  opts: boolean | AddEventListenerOptions = false,
) => {
  if (Array.isArray(event)) {
    event.forEach((e) => {
      on(element, e, handler);
    });

    return;
  }

  if (element && event && handler) {
    element.addEventListener(event, handler, opts);
  }
};

export const off = (
  element: HandlerElementSelector,
  event: string | string[],
  handler: (...args: any) => any,
  opts: boolean | EventListenerOptions = false,
) => {
  if (Array.isArray(event)) {
    event.forEach((e) => {
      off(element, e, handler);
    });

    return;
  }

  if (element && event) {
    element.removeEventListener(event, handler, opts);
  }
};

export const once = (
  el: HandlerElementSelector,
  event: string | string[],
  fn: (...args: any) => any,
) => {
  const listener = (...args: any) => {
    if (fn) {
      fn.apply(this, args);
    }

    off(el, event, listener);
  };

  on(el, event, listener);

  return listener;
};
