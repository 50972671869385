import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Meeting from 'pages/Meeting';
import Present from 'pages/Present';
import SelfDetailing from 'pages/SelfDetailing';
import Recorder from 'pages/Recorder';
import NotFound from 'pages/NotFound';

import Spinner from 'ui/Spinner';

const RouterTeams = lazy(() => import('./RouterTeams'));
const Document = lazy(() => import('pages/Document'));

const RouterBase = () => (
  <Router>
    <Switch>
      <Route path="/present/mobile/:id">
        <SelfDetailing />
      </Route>
      <Route path="/present/embed/:id">
        <Present isPresentOnly />
      </Route>
      <Route path="/present/:id">
        <Present />
      </Route>
      <Route path="/p/:id">
        <Present />
      </Route>
      <Route path="/recorder/:id">
        <Recorder />
      </Route>
      <Route path="/c/:id">
        <Present isConferenceOnly />
      </Route>
      <Route path="/view/:id">
        <SelfDetailing />
      </Route>
      <Route path="/s/:id">
        <SelfDetailing />
      </Route>
      <Route path="/indexIOS.html">
        <Meeting isEmbed />
      </Route>
      <Route path="/embed">
        <Meeting isEmbed />
      </Route>
      <Route
        path="/teams"
        render={() => {
          window.location.href = 'https://help.customertimes.com/home/en-us/';

          return null;
        }}
      />
      <Route path="/ms-teams">
        <Suspense fallback={<Spinner isCenter />}>
          <RouterTeams />
        </Suspense>
      </Route>
      <Route path="/terms">
        <Suspense fallback={<Spinner isCenter />}>
          <Document doc="termsDocument" />
        </Suspense>
      </Route>
      <Route path="/eula">
        <Suspense fallback={<Spinner isCenter />}>
          <Document doc="eulaDocument" />
        </Suspense>
      </Route>
      <Route path="/privacy">
        <Suspense fallback={<Spinner isCenter />}>
          <Document doc="privacyDocument" />
        </Suspense>
      </Route>
      <Route exact path="/">
        <Meeting />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>
);

export default RouterBase;
