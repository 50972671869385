import { useTranslation } from 'i18n-lite';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store';
import { getCurrentUser } from 'store/selectors';
import { Meeting } from 'store/models';
import LoginLayout from 'components/LoginLayout';
import Button from 'ui/Button';
import Spinner from 'ui/Spinner';

import styles from './Waiting.module.scss';

interface WaitingLayoutProps {
  meet: Meeting;
}

const Waiting: React.FC<WaitingLayoutProps> = ({ meet }) => {
  const { t } = useTranslation('presentation');
  const rootDispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(getCurrentUser);

  return (
    <LoginLayout isLogo customLogo={meet.orgLogo}>
      <div className={styles.wrapper}>
        <h2>
          <div className={styles.loader}>
            <Spinner />
          </div>
          {t('waiting.title')}
        </h2>
        <div className={styles.userName}>{currentUser?.name}</div>
        <Button color="secondary" size="sm" onClick={() => rootDispatch({ type: 'logout' })}>
          {t('waiting.logout')}
        </Button>
      </div>
    </LoginLayout>
  );
};

export default Waiting;
