import { createContext, useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';

export const SocketContext = createContext<Socket | null>(null);

interface QueryParams {
  [key: string]: string;
}

interface Events {
  [key: string]: (...args: any[]) => any;
}

interface ProviderProps {
  url: string;
  query?: QueryParams;
  token?: string;
  events?: Events;
}

function useSocket(url: string, query?: QueryParams, token?: string, events?: Events) {
  const [connection, setConnection] = useState<Socket | null>(null);

  useEffect(() => {
    const parsedUrl = new URL(url);
    const ev = events && Object.entries(events);

    const socket = io(parsedUrl.origin, {
      path: parsedUrl.pathname,
      query,
      upgrade: false,
      auth: {
        token,
      },
      closeOnBeforeunload: false,
      transports: ['websocket'],
    });

    if (ev) {
      ev.forEach(([name, value]) => {
        socket.on(name, value);
      });
    }

    setConnection(socket);

    return () => {
      if (ev) {
        ev.forEach(([name, value]) => {
          socket.off(name, value);
        });
      }

      socket.disconnect();

      setConnection(null);
    };
  }, [url, query, token, events]);

  return connection;
}

const Provider: React.FC<ProviderProps> = ({ url, query, token, children, events }) => {
  const socket = useSocket(url, query, token, events);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default Provider;
