import { Meeting, ID, Presentation, PresentationScenario, MeetingInfo } from 'store/models';

export const initialState: {
  meet: Meeting | null;
  currentSlideId: ID | null;
  currentPresentationId: ID | null;
  focusUserId: ID | null;
  isRecording: boolean;
  presenterLeaveAt: MeetingInfo['presenterLeaveAt'];
  error: 'unhandled' | '404' | 'joinPolicy' | 'sessionSupplant' | 'integrationPolicy' | null;
  userPointers: Set<ID>;
  isExit: boolean;
} = {
  meet: null,
  currentSlideId: null,
  currentPresentationId: null,
  presenterLeaveAt: null,
  focusUserId: null,
  isRecording: false,
  error: null,
  userPointers: new Set(),
  isExit: false,
};

export type Actions =
  | {
      type: 'initMeet' | 'meetUpdate';
      payload: {
        meet: Meeting;
      };
    }
  | {
      type: 'setLikes';
      payload: {
        value: boolean;
      };
    }
  | {
      type: 'setPointer';
      payload: {
        userId: ID;
        value: boolean;
      };
    }
  | {
      type: 'setFocusUserId';
      payload: {
        id: ID | null;
      };
    }
  | {
      type: 'meetInfoUpdate';
      payload: {
        meetInfo: MeetingInfo;
      };
    }
  | {
      type: 'setPresentId' | 'setSlideId';
      payload: {
        id: ID;
        slideId?: ID;
      };
    }
  | {
      type: 'setExit';
      payload: {
        value: boolean;
      };
    }
  | {
      type: 'onFinish';
    }
  | {
      type: 'error';
      status: typeof initialState['error'];
    }
  | {
      type: 'setIsRecording';
      payload: {
        value: boolean;
      };
    };

export function reducer(state: typeof initialState, action: Actions): typeof initialState {
  switch (action.type) {
    case 'initMeet': {
      const { presentations } = action.payload.meet;

      return {
        ...state,
        ...action.payload,
        currentPresentationId: presentations[0]?._id ?? null,
        currentSlideId: presentations[0]?.slides[0]?._id ?? null,
      };
    }

    case 'setPresentId': {
      if (!state.meet) {
        return state;
      }

      let currentSlideId = action.payload.slideId;

      if (!currentSlideId) {
        const flatPres = state.meet.presentations.reduce((acc, item) => {
          acc.set(item._id, item);

          if (item.scenarios.length) {
            item.scenarios.forEach((scen) => {
              acc.set(scen._id, scen);
            });
          }

          return acc;
        }, new Map<ID, Presentation | PresentationScenario>());

        const curPres = flatPres.get(action.payload.id);

        currentSlideId = curPres?.slides[0]?._id;
      }

      return {
        ...state,
        currentPresentationId: action.payload.id,
        currentSlideId: currentSlideId || null,
      };
    }

    case 'setSlideId': {
      if (!state.meet) {
        return state;
      }

      return {
        ...state,
        currentSlideId: action.payload.id,
      };
    }

    case 'setExit': {
      return {
        ...state,
        isExit: action.payload.value,
      };
    }

    case 'meetUpdate': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'meetInfoUpdate': {
      return {
        ...state,
        focusUserId: action.payload.meetInfo.focusUserId,
        currentPresentationId: action.payload.meetInfo.currentPresentationId,
        currentSlideId: action.payload.meetInfo.currentSlideId,
        userPointers: new Set(action.payload.meetInfo.presentManageUsers),
        presenterLeaveAt: action.payload.meetInfo.presenterLeaveAt,
        isRecording: action.payload.meetInfo.isRecording,
      };
    }

    case 'setLikes': {
      if (!state.meet) {
        return state;
      }

      return {
        ...state,
        meet: {
          ...state.meet,
          isLikes: action.payload.value,
        },
      };
    }

    case 'onFinish': {
      if (!state.meet) {
        return state;
      }

      return {
        ...state,
        meet: {
          ...state.meet,
          finishedAt: new Date().toISOString(),
        },
      };
    }

    case 'setPointer': {
      const userPointers = new Set(state.userPointers);

      userPointers[action.payload.value ? 'add' : 'delete'](action.payload.userId);

      return {
        ...state,
        userPointers,
      };
    }

    case 'setFocusUserId': {
      return {
        ...state,
        focusUserId: action.payload.id,
      };
    }

    case 'setIsRecording': {
      return {
        ...state,
        isRecording: action.payload.value,
      };
    }

    case 'error': {
      return {
        ...state,
        error: action.status,
      };
    }

    default:
      return initialState;
  }
}
