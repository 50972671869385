import cn from 'classnames';

import { ID, CurrentPresentation, Presentation } from 'store/models';
import SlidePager from './SlidePager';
import HeaderToolbar from './HeaderToolbar';
import PresentSelect from './PresentSelect';

import styles from './PresenterLayout.module.scss';

interface PresenterHeaderProps extends React.ComponentProps<typeof HeaderToolbar> {
  slideCounts?: number;
  currentSlide: number;
  goTo: (e: number) => void;
  presentations: Presentation[];
  currentPresentation?: CurrentPresentation;
  setCurrentPresent: (id: ID) => void;
  isFooter?: boolean;
}

const PresenterHeader: React.FC<PresenterHeaderProps> = ({
  onFullscreen,
  slideCounts,
  currentSlide,
  goTo,
  presentations,
  currentPresentation,
  isFullscreen,
  setCurrentPresent = () => {},
  zoom,
  setZoom,
  isShowFullscreen,
  isFooter = false,
}) => {
  const WrapperComponent = isFooter ? 'footer' : 'header';

  return (
    <WrapperComponent className={cn(styles.header, isFullscreen && styles.headerFullscreen)}>
      <div className={styles.headerLeft}>
        {!!presentations.length && (
          <PresentSelect
            direction={isFooter ? 'top' : 'bottom'}
            presentations={presentations}
            currentPresentation={currentPresentation}
            setCurrentPresent={setCurrentPresent}
          />
        )}
        {currentPresentation && (
          <span className={styles.headerName}>{currentPresentation.name}</span>
        )}
      </div>
      <div className={styles.headerCenter}>
        {slideCounts && <SlidePager current={currentSlide} count={slideCounts} goTo={goTo} />}
      </div>
      <HeaderToolbar
        isBottomPosition={isFooter}
        isShowFullscreen={isShowFullscreen}
        isLikes={false}
        isFullscreen={isFullscreen}
        onFullscreen={onFullscreen}
        zoom={zoom}
        setZoom={setZoom}
      />
    </WrapperComponent>
  );
};

export default PresenterHeader;
