import { useState, memo, useMemo } from 'react';
import cn from 'classnames';

import Tooltip from '../Tooltip';

import styles from './Avatar.module.scss';

interface AvatarProps {
  name: string;
  className?: string;
  isTooltip?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ name, className, isTooltip = true }) => {
  const [isVisible, setVisible] = useState(false);
  const zipName = useMemo(() => {
    const names = name.split(' ');

    names.slice(0, 2);

    return names.map((n) => n[0]).join('');
  }, [name]);

  return (
    <Tooltip
      isVisible={isVisible}
      setVisible={setVisible}
      content={<div>{name}</div>}
      tooltipClassName={styles.tooltip}
      render={(ref) => (
        <div
          onMouseOver={() => setVisible(isTooltip)}
          onMouseOut={() => setVisible(false)}
          ref={ref}
          className={cn(styles.avatar, className)}
        >
          {zipName}
        </div>
      )}
    />
  );
};

export default memo(Avatar);
