import { useTranslation } from 'i18n-lite';

import styles from './Placeholder.module.scss';

import { ReactComponent as WarnIcon } from 'assets/WarnIcon.svg';

interface PlaceholderProps {
  type?: 'notFound';
}

const Placeholder: React.FC<PlaceholderProps> = ({ type = 'notFound' }) => {
  const { t } = useTranslation('presentation');

  return (
    <div className={styles.wrapper}>
      <div className={styles.placeholder}>
        <div className={styles.heading}>
          <WarnIcon className={styles.icon} />
          <h3 className={styles.title}>{t(`errors.${type}.title`)}</h3>
        </div>
        <div className={styles.caption}>{t(`errors.${type}.caption`)}</div>
      </div>
    </div>
  );
};

export default Placeholder;
