import { useMemo, useCallback } from 'react';
import { useTranslation } from 'i18n-lite';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import last from 'lodash/last';

import { useEvent, useRequest } from 'hooks/socket';
import { useScreenShareEnabled } from 'hooks/jitsi';
import useConfirmClose from 'hooks/useConfirmClose';
import { UserTracksCollection, RequestDevice } from 'hooks/jitsi/types';
import { AppDispatch } from 'store';
import { Message, ID } from 'store/models';
import useEventHandler from 'hooks/useEventHandler';
import useConference from 'hooks/useConference';
import { useDescribePublicAppSdk } from 'sdk';
import UserAudio from './UserAudio';
import ConnectionState from './ConnectionState';

import DeviceControls from './DeviceControls';
import Call from './Call';
import Chat from './Chat';

import styles from './Conference.module.scss';

import { ReactComponent as CommentsIcon } from 'assets/CommentsIcon.svg';
import Text from './Text';

type TabsConfig = {
  [key: string]: {
    icon?: React.ReactNode;
    content: typeof Call | typeof Chat;
  };
};

type Tabs = 'conference' | 'comments';

interface ConferenceProps {
  connectionState?: 'pending' | 'error' | 'ready';
  tracks: UserTracksCollection;
  meetId: string;
  isPresenter: boolean;
  isLikes: boolean;
  userPointers: Set<ID>;
  isChat?: boolean;
  focusUserId: ID | null;
  setFocusUserId: (id: ID | null) => void;
  isVideoAble?: boolean;
  isRecord: boolean;
  isRecordable: boolean;
  isMeetActions?: boolean;
  setIsRecord: (val: boolean) => void;
  activeDevices: {
    isVideo: boolean;
    isAudio: boolean;
    isScreenShare: boolean;
  };
  requestDevices: {
    audio: RequestDevice;
    video: RequestDevice;
    screenShare: RequestDevice;
  };
  isSelfPointer: boolean;
  setLikes: (val: boolean) => void;
  setPointer: ({ userId, value }: { userId: ID; value: boolean }) => void;
  setSelfPointer: (val: boolean) => void;
  onFinish: () => void;
  onMessage: (e: { text: string }) => any;
}

const Conference: React.FC<ConferenceProps> = ({
  connectionState,
  tracks,
  meetId,
  isPresenter,
  isLikes,
  activeDevices,
  requestDevices,
  userPointers,
  setLikes,
  setPointer,
  focusUserId,
  setFocusUserId,
  isRecord,
  isRecordable,
  setIsRecord,
  isSelfPointer,
  setSelfPointer,
  isChat = true,
  isVideoAble = true,
  isMeetActions = true,
  onFinish,
  onMessage,
}) => {
  const { t } = useTranslation('conference');
  const rootDispatch = useDispatch<AppDispatch>();

  useConfirmClose(t('closeMessage'), isPresenter);

  const { isAudio, isVideo, isScreenShare } = activeDevices;
  const { state, dispatch } = useConference();

  const isUreadMessages = useMemo(
    () => (last(state.messages)?._id ?? null) !== state.lastReadMessage,
    [state.lastReadMessage, state.messages],
  );

  const getDevicesState = useEventHandler(
    () => ({ ...activeDevices, isRecord }),
    [activeDevices, isRecord],
  );

  useDescribePublicAppSdk({
    getDevicesState,
  });

  const isScreenShareEnabled = useScreenShareEnabled();

  useEvent('chat-message', ({ message }: { message: Message }) => {
    dispatch({ type: 'newMessage', payload: { message } });

    if (state.activeTab !== 'comments') {
      rootDispatch({
        type: 'addNotifcation',
        notificaiton: {
          title: <b>{message.author.name}</b>,
          caption: <Text text={message.text} />,
        },
      });
    }
  });

  const setTab = useCallback(
    (tab: Tabs) => {
      dispatch({ type: 'setActiveTab', payload: { tab } });
    },
    [dispatch],
  );

  const tabs = useMemo(() => {
    const t: TabsConfig = {
      conference: {
        content: Call,
      },
    };

    if (isChat) {
      t.comments = {
        icon: <CommentsIcon />,
        content: Chat,
      };
    }

    return t;
  }, [isChat]);

  const TabContent = useMemo(
    () => tabs[state.activeTab]?.content ?? tabs.conference.content,
    [state.activeTab, tabs],
  );

  const setMuted = useRequest<{ userId: ID; value: boolean }, { status: number }>(
    'meet.setMuteUser',
  );
  const startRecord = useRequest<{}, { status: number }>('meet.startRecord');
  const stopRecord = useRequest<{}, { status: number }>('meet.stopRecord');
  const updateFocusUserId = useRequest<{ userId: ID | null }, { status: number }>(
    'meet.setFocusUserId',
  );

  const handleToggleRecord = useCallback(
    (value: boolean) => {
      (value ? startRecord() : stopRecord()).then(() => {
        setIsRecord(value);
      });
    },
    [startRecord, stopRecord, setIsRecord],
  );

  const handlePointers = useCallback(
    (userId: ID, value: boolean) => {
      setPointer({ userId, value });
    },
    [setPointer],
  );

  const handleMuted = useCallback(
    (userId: ID, value: boolean) => {
      dispatch({ type: 'setMuted', payload: { userId, value } });

      setMuted({ userId, value });
    },
    [setMuted, dispatch],
  );

  const handleFocusUserId = useCallback(
    (id: ID | null) => {
      updateFocusUserId({ userId: id });

      setFocusUserId(id);
    },
    [updateFocusUserId, setFocusUserId],
  );

  return (
    <div className={styles.conference}>
      <ConnectionState connectionState={connectionState} userId="currentUser" />
      <DeviceControls
        isRecord={isRecord}
        isRecordable={isPresenter && isRecordable}
        setRecord={handleToggleRecord}
        isAudio={isAudio}
        isVideo={isVideo}
        isVideoAble={isVideoAble}
        isScreenShare={isScreenShare}
        isScreenShareAble={isScreenShareEnabled}
        setAudio={requestDevices.audio}
        setVideo={requestDevices.video}
        setScreenShare={requestDevices.screenShare}
        onEnd={onFinish}
      />
      {Object.keys(tracks).map((id) => {
        const track = tracks[id].audio;

        if (id === 'currentUser' || !track || state.mutedUsers.has(id)) {
          return null;
        }

        return <UserAudio key={id} track={track} />;
      })}
      {isRecord && <div className={styles.recording}>{t('audioRecord')}</div>}
      <div className={styles.content}>
        <div className={styles.tabs}>
          {(Object.keys(tabs) as Array<keyof typeof tabs>).map((tab) => (
            <div
              key={tab}
              className={cn(
                styles.tab,
                state.activeTab === tab && styles.tabActive,
                tab === 'comments' && isUreadMessages && styles.tabNotification,
              )}
              onClick={() => setTab(tab as Tabs)}
            >
              <span>{t(`tabs.${tab}`)}</span>
              {tabs[tab].icon && <div className={styles.tabIcon}>{tabs[tab].icon}</div>}
            </div>
          ))}
        </div>
        <TabContent
          meetId={meetId}
          users={state.users}
          tracks={tracks}
          isPresenter={isPresenter}
          isMeetActions={isMeetActions}
          isLikes={isLikes}
          setLikes={setLikes}
          isSelfPointer={isSelfPointer}
          focusUserId={focusUserId}
          setFocusUserId={handleFocusUserId}
          userPointers={userPointers}
          mutedUsers={state.mutedUsers}
          setMuted={handleMuted}
          setSelfPointer={setSelfPointer}
          setPointer={handlePointers}
          onMessage={onMessage}
          messages={state.messages}
          lastReadMessage={state.lastReadMessage}
        />
      </div>
    </div>
  );
};

export default Conference;
