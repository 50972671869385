import { useMemo } from 'react';
import { useTranslation } from 'i18n-lite';
import { useRouteMatch, Link } from 'react-router-dom';
import random from 'lodash/random';
import cn from 'classnames';

import Button from 'ui/Button';

import styles from './LoginLayout.module.scss';

import { ReactComponent as BackIcon } from 'assets/BackIcon.svg';
import Logo from 'assets/logo.png';

// thx to unable to disable hooks
function LayoutBackButton() {
  const { t } = useTranslation();
  const match = useRouteMatch('/present/embed/:id');
  const backLink = useMemo(() => (match ? '/embed' : '/'), [match]);

  return (
    <Button
      component={Link}
      to={backLink}
      isRounded
      size="sm"
      color="secondary"
      className={styles.backButton}
      title={t(':ui.back')}
    >
      <BackIcon />
    </Button>
  );
}

interface LoginLayoutProps {
  isBackButton?: boolean;
  isWide?: boolean;
  heading?: React.ReactNode;
  footer?: React.ReactNode;
  isLogo?: boolean;
  customLogo?: string;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
  isBackButton = false,
  isWide = false,
  heading,
  children,
  footer,
  isLogo = false,
  customLogo,
}) => {
  const { t } = useTranslation();
  const bgImage = useMemo(() => {
    const bgIndex = random(1, 30);

    return require(`assets/bg/${bgIndex}.jpg`);
  }, []);

  return (
    <div className={styles.loginWrapper} style={{ backgroundImage: `url(${bgImage})` }}>
      {heading}
      <div className={cn(styles.loginBlock, isWide && styles.loginBlockWide)}>
        {isBackButton && <LayoutBackButton />}
        {isLogo && <img className={styles.logo} src={customLogo || Logo} alt={t(':ui.logo')} />}
        {children}
      </div>
      {footer}
    </div>
  );
};

export default LoginLayout;
