import { useEffect } from 'react';
import { once, off } from 'utils/dom';
import NoSleep from 'nosleep.js';

let noSleep: NoSleep;

function patchIos(noSleep: NoSleep) {
  const video: HTMLVideoElement = (noSleep as any).noSleepVideo;

  if (!video) {
    return;
  }

  video.setAttribute('title', 'CT presenter wake lock');
  Object.assign(video.style, {
    position: 'absolute',
    left: '-100%',
    top: '-100%',
  });
  document.querySelector('body')?.append(video);
}

export default function useAwake(isEnabled: boolean = true) {
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (!noSleep) {
      noSleep = new NoSleep();

      patchIos(noSleep);
    }

    const handler = once(document, 'click', () => {
      noSleep.enable();
    });

    return () => {
      off(document, 'click', handler);
      noSleep.disable();
    };
  }, [isEnabled]);
}
