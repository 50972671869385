import { useRef, useEffect, useCallback, useState, useMemo, createRef } from 'react';
import throttle from 'lodash/throttle';
import omit from 'lodash/omit';
import cn from 'classnames';
import getCssSelector from 'css-selector-generator';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Slide, ID } from 'store/models';
import { useBroadcast, useEvent } from 'hooks/socket';
import useFrameAspect from 'hooks/useFrameAspect';
import Cursor from './Cursor';

import styles from './Canvas.module.scss';
import fadeTransition from 'ui/transitions/Fade.module.scss';

const { NODE_ENV } = process.env;

function patchWindow(root: Window) {
  root.localStorage.clear = () => {
    console.warn(
      'You should not use localStorage.clear method! This is global object. It could effect of whole app behaviour.',
    );
  };
}

patchWindow(window);

interface CanvasProps {
  color?: 'white' | 'gray';
  isPadded?: boolean;
  isProxyEvents?: boolean;
  slide: Slide;
  orientation?: 'horizontal' | 'vertical';
  isPointer: boolean;
  title: string;
  zoom?: number;
  onFrameClick?: (e: { elementPath: string }) => any;
}

type UiTouchModel = Omit<Touch, 'target'> & {
  target: string;
};

interface UiEvent {
  eventType: string;
  elementPath: string;
  event: {
    type: string;
    bubbles: boolean;
    cancelable: boolean;
    altKey: boolean;
    ctrlKey: boolean;
    timeStamp: number;
    pageX?: number;
    pageY?: number;
    scrollTop?: number;
    scrollLeft?: number;
    clientX?: number;
    clientY?: number;
    value?: string;
    touches?: UiTouchModel[];
    targetTouches?: UiTouchModel[];
    changedTouches?: UiTouchModel[];
  };
}

function getElementPath(target: Element | EventTarget): string {
  if (!target) {
    return 'document';
  }

  return getCssSelector(target as Element, {
    combineWithinSelector: false,
    includeTag: true,
  });
}

function serializeTouchList(list: TouchList): UiTouchModel[] {
  return Array.from(list).map((touch) => ({
    altitudeAngle: (touch as any).altitudeAngle,
    azimuthAngle: (touch as any).azimuthAngle,
    touchType: (touch as any).touchType,
    clientX: touch.clientX,
    clientY: touch.clientY,
    force: touch.force,
    identifier: touch.identifier,
    pageX: touch.pageX,
    pageY: touch.pageY,
    radiusX: touch.radiusX,
    radiusY: touch.radiusY,
    rotationAngle: touch.rotationAngle,
    screenX: touch.screenX,
    screenY: touch.screenY,
    target: getElementPath(touch.target),
  }));
}

function unserializeTouchList(root: Element | Document, list: UiTouchModel[]): Touch[] {
  return list.reduce((acc: Touch[], event) => {
    const target = root.querySelector(event.target);

    if (target) {
      acc.push(new Touch({ ...event, target }));
    }

    return acc;
  }, []);
}

function isMouseMove(event: any): event is MouseEvent {
  return event.type === 'mousemove';
}

function isTouchMove(event: any): event is TouchEvent {
  return event.type === 'touchmove';
}

function mapTouchToMouse(eventType: 'touchmove' | 'touchend' | 'touchstart' | string) {
  switch (eventType) {
    case 'touchstart':
      return 'mousedown';

    case 'touchmove':
      return 'mousemove';

    case 'touchend':
      return 'mouseup';

    default:
      return null;
  }
}

type PointerEventType =
  | MouseEvent
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | TouchEvent
  | React.TouchEvent<HTMLDivElement>;

const noop = () => {};

const Canvas: React.FC<CanvasProps> = ({
  slide,
  isPointer,
  title,
  onFrameClick,
  orientation = 'horizontal',
  zoom = 1,
  isProxyEvents = true,
  color = 'gray',
  isPadded = true,
}) => {
  const [pointers, setPoiner] = useState<{ [key: string]: { x: number; y: number } }>({});
  const { wrapperRef, frameRef, frameWrapperStyles, frameRect } = useFrameAspect({ zoom }, [
    orientation,
  ]);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  let pointerBroadcast = useBroadcast<{ x: number | null; y: number | null }>('meet.pointerEvent');
  let netEventProxy = useBroadcast<UiEvent>('meet.proxyUiEvent');

  if (!isProxyEvents) {
    pointerBroadcast = noop;
    netEventProxy = noop;
  }

  const onRemotePointer = useCallback(
    ({ userId, x, y }: { userId: ID; x: number | null; y: number | null }) => {
      if (!x || !y) {
        setPoiner((state) => omit(state, userId));

        return;
      }

      setPoiner((state) => ({
        ...state,
        [userId]: {
          x:
            x / (frameRect.clientWidth / frameRect.width) +
            (frameRect.left - frameRect.parentOffsetLeft),
          // pointers add in canvas context
          y:
            y / (frameRect.clientHeight / frameRect.height) +
            (frameRect.top - frameRect.parentOffsetTop),
        },
      }));
    },
    [frameRect],
  );

  useEvent('meet-pointerEvent', onRemotePointer);

  const onPointerMove = useMemo(
    () =>
      throttle((event: PointerEventType) => {
        if (!event.isTrusted) {
          return;
        }

        if (
          event.type === 'mouseleave' ||
          event.type === 'touchend' ||
          event.type === 'touchcancel'
        ) {
          pointerBroadcast({ x: null, y: null });

          return;
        }

        if (isMouseMove(event)) {
          pointerBroadcast({
            x: event.clientX,
            y: event.clientY,
            // Relative to canvas coords of pointers
            // x: (frameRect.clientWidth / frameRect.width) * (event.clientX - frameRect.left),
            // y: (frameRect.clientHeight / frameRect.height) * (event.clientY - frameRect.top),
          });

          return;
        }

        if (isTouchMove(event)) {
          pointerBroadcast({
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
          });

          return;
        }
      }, 100),
    [pointerBroadcast],
  );

  const onFrameUiEvent = useCallback(
    (ev: any) => {
      if (!ev.isTrusted) {
        return;
      }

      const eventType = ev.constructor.name;
      const elementPath = getElementPath(ev.target);

      if (ev.type === 'click') {
        onFrameClick && onFrameClick({ elementPath });
      }

      netEventProxy({
        eventType,
        elementPath,
        event: {
          altKey: ev.altKey,
          ctrlKey: ev.ctrlKey,
          bubbles: ev.bubbles,
          cancelable: ev.cancelable,
          type: ev.type,
          pageX: ev?.pageX,
          pageY: ev?.pageY,
          clientX: ev?.clientX,
          clientY: ev?.clientY,
          // TODO: fix scroll event doubling
          // scrollTop: ev?.target?.scrollTop,
          // scrollLeft: ev?.target?.scrollLeft,
          timeStamp: ev.timeStamp,
          value: ev?.target?.value,
          touches: ev.touches ? serializeTouchList(ev.touches) : undefined,
          targetTouches: ev.targetTouches ? serializeTouchList(ev.targetTouches) : undefined,
          changedTouches: ev.changedTouches ? serializeTouchList(ev.changedTouches) : undefined,
        },
      });
    },
    [netEventProxy, onFrameClick],
  );

  const onUiEvent = useCallback(
    ({ eventType, elementPath, event }: UiEvent) => {
      const iframeEl = iframeRef.current;

      if (!iframeEl || !iframeEl.contentWindow) {
        return;
      }

      const el = iframeEl.contentWindow.document.querySelector(elementPath);

      switch (eventType) {
        case 'PointerEvent':
        case 'MouseEvent': {
          const { type, ...settings } = event;

          const domEvent = new MouseEvent(type, settings);

          el?.dispatchEvent(domEvent);

          return;
        }

        case 'TouchEvent': {
          const { type, ...settings } = event;

          // FF desktop don't have TouchEvent
          if ('TouchEvent' in window) {
            const domEvent = new TouchEvent(type, {
              ...settings,
              touches: unserializeTouchList(
                iframeEl.contentWindow.document,
                settings?.touches ?? [],
              ),
              targetTouches: unserializeTouchList(
                iframeEl.contentWindow.document,
                settings?.targetTouches ?? [],
              ),
              changedTouches: unserializeTouchList(
                iframeEl.contentWindow.document,
                settings?.changedTouches ?? [],
              ),
            });

            el?.dispatchEvent(domEvent);
          }

          const mouseType = mapTouchToMouse(type);

          if (mouseType) {
            settings.clientY = settings.touches?.[0]?.clientY;
            settings.clientX = settings.touches?.[0]?.clientX;

            const mouseEvent = new MouseEvent(mouseType, settings);

            el?.dispatchEvent(mouseEvent);
          }

          return;
        }

        default: {
          if (event.type === 'input') {
            if (el) {
              (el as HTMLInputElement).value = event.value || '';
            }

            return;
          }

          if (event.type === 'scroll') {
            if (el) {
              if (event.scrollTop) {
                el.scrollTop = event.scrollTop;
              }

              if (event.scrollLeft) {
                el.scrollLeft = event.scrollLeft;
              }
            }

            return;
          }

          throw new Error(`Unsupported event type: ${eventType}`);
        }
      }
    },
    [iframeRef],
  );

  useEvent('meet-uiEvent', onUiEvent);

  useEffect(() => {
    const iframeEl = iframeRef.current;

    if (!iframeEl) {
      return;
    }

    const iframeWindow = iframeEl.contentWindow;

    if (!iframeWindow) {
      console.warn("Canvas: iframe document could'nt reached! Check cross origin.");

      return;
    }

    patchWindow(iframeWindow);

    if (!isPointer) {
      return;
    }

    const opts = {
      capture: true,
      passive: true,
    };

    iframeWindow.addEventListener('mousemove', onPointerMove, opts);
    iframeWindow.addEventListener('mouseleave', onPointerMove, opts);
    iframeWindow.addEventListener('touchmove', onPointerMove, opts);
    iframeWindow.addEventListener('touchend', onPointerMove, opts);
    iframeWindow.addEventListener('touchcancel', onPointerMove, opts);

    const uiEvents: [string, (...args: any[]) => void][] = [
      ['click', onFrameUiEvent],
      ['input', onFrameUiEvent],
      // drag case
      [
        'mousedown',
        (ev) => {
          onFrameUiEvent(ev);

          iframeWindow.addEventListener('mousemove', onFrameUiEvent, opts);
        },
      ],
      [
        'mouseup',
        (ev) => {
          onFrameUiEvent(ev);

          iframeWindow.removeEventListener('mousemove', onFrameUiEvent, opts);
        },
      ],
      ['mouseenter', onFrameUiEvent],
      ['mouseleave', onFrameUiEvent],
      ['contextmenu', onFrameUiEvent],
      ['dblclick', onFrameUiEvent],
      [
        'touchstart',
        (ev) => {
          onFrameUiEvent(ev);

          iframeWindow.addEventListener('touchmove', onFrameUiEvent, opts);
        },
      ],
      [
        'touchend',
        (ev) => {
          onFrameUiEvent(ev);

          iframeWindow.removeEventListener('touchmove', onFrameUiEvent, opts);
        },
      ],
      [
        'touchcancel',
        (ev) => {
          onFrameUiEvent(ev);

          iframeWindow.removeEventListener('touchmove', onFrameUiEvent, opts);
        },
      ],
      // TODO: uncomment then fix scroll event doubling
      // ['scroll', onFrameUiEvent],
    ];

    for (const [eventName, handler] of uiEvents) {
      iframeWindow.addEventListener(eventName, handler, opts);
    }

    return () => {
      if (!iframeWindow || !iframeWindow.removeEventListener) {
        return;
      }

      iframeWindow.removeEventListener('mousemove', onPointerMove, opts);
      iframeWindow.removeEventListener('mouseleave', onPointerMove, opts);
      iframeWindow.removeEventListener('touchmove', onPointerMove, opts);
      iframeWindow.removeEventListener('touchend', onPointerMove, opts);
      iframeWindow.removeEventListener('touchcancel', onPointerMove, opts);

      for (const [eventName, handler] of uiEvents) {
        iframeWindow.removeEventListener(eventName, handler, opts);
      }

      // in case of mouse up not fired
      iframeWindow.removeEventListener('mousemove', onFrameUiEvent, opts);
      iframeWindow.removeEventListener('touchmove', onFrameUiEvent, opts);
    };
  }, [iframeRef, onPointerMove, onFrameUiEvent, isPointer, slide.url]);

  const handleFrameLoad = useCallback((ev: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    const bridgeInitEvent = new CustomEvent('WebViewJavascriptBridgeReady');

    (ev.target as any).contentDocument?.dispatchEvent(bridgeInitEvent);
  }, []);

  return (
    <div
      ref={wrapperRef as any}
      className={cn(
        styles.canvas,
        styles[`canvasColor--${color}`],
        isPadded && styles.canvasPadded,
      )}
    >
      <div
        ref={frameRef}
        className={cn(
          styles.frameWrapper,
          orientation === 'vertical' && styles.frameWrapperVertical,
        )}
        style={frameWrapperStyles}
        // onPointerMove={isPointer ? onPointerMove : undefined}
      >
        <iframe
          key={slide.url} // Prevent history events inside of iframe
          onMouseLeave={isPointer ? onPointerMove : undefined}
          className={cn(styles.frame, !isPointer && styles.isViewOnly)}
          title={slide.title || title}
          src={
            NODE_ENV === 'development'
              ? slide.url.replace('https://www.ctclm.com', window.location.origin)
              : slide.url
          }
          scrolling="no"
          loading="lazy"
          ref={iframeRef}
          onLoad={handleFrameLoad}
        />
      </div>
      <TransitionGroup>
        {Object.entries(pointers).map(([pointer, coords]) => {
          const elRef = createRef<HTMLDivElement>();

          return (
            <CSSTransition key={pointer} timeout={300} classNames={fadeTransition} nodeRef={elRef}>
              <Cursor ref={elRef} userId={pointer} coords={coords} />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default Canvas;
