import { useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'i18n-lite';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { Slide, ID } from 'store/models';
import { AppDispatch } from 'store';
import { getFlag } from 'store/selectors';
import HideButton from 'ui/HideButton';
import { useAnalytic } from 'hooks/analytic';

import styles from './SlidesPreview.module.scss';
import fadeTransition from 'ui/transitions/Fade.module.scss';

import { ReactComponent as CarpetIcon } from 'assets/CarpetIcon.svg';
import SlidesPreviewItem from './SlidesPreviewItem';

interface SlidesPreviewProps {
  isVertical?: boolean;
  slides: Slide[];
  currentSlideId: ID;
  onSlide: (id: ID) => void;
}

const SlidesPreview: React.FC<SlidesPreviewProps> = ({
  isVertical = false,
  slides,
  currentSlideId,
  onSlide,
}) => {
  const { t } = useTranslation('presentation');
  const trackEvent = useAnalytic();
  const nodeRef = useRef(null);
  const slidesMeta = useMemo(() => {
    const currentIndex = slides.findIndex((slide) => slide._id === currentSlideId);

    return {
      back: slides[currentIndex - 1]?._id ?? null,
      next: slides[currentIndex + 1]?._id ?? null,
    };
  }, [currentSlideId, slides]);
  const isOpen = useSelector(getFlag('isSliderPreviewOpen'));
  const dispatch = useDispatch<AppDispatch>();

  const toggleOpen = useCallback(() => {
    dispatch({ type: 'setFlag', name: 'isSliderPreviewOpen', value: !isOpen });
  }, [dispatch, isOpen]);

  const onBack = useCallback(() => {
    if (slidesMeta.back) {
      onSlide(slidesMeta.back);
      trackEvent({ event: 'buttons_slide_view', payload: {} });
    }
  }, [trackEvent, slidesMeta.back, onSlide]);

  const onNext = useCallback(() => {
    if (slidesMeta.next) {
      onSlide(slidesMeta.next);
      trackEvent({ event: 'buttons_slide_view', payload: {} });
    }
  }, [trackEvent, slidesMeta.next, onSlide]);

  return (
    <div
      className={cn(
        styles.container,
        !isOpen && styles.containerIsClose,
        isVertical && styles.containerIsVertical,
      )}
    >
      <CSSTransition
        in={isOpen}
        nodeRef={nodeRef}
        mountOnEnter
        unmountOnExit
        timeout={300}
        classNames={fadeTransition}
      >
        <div ref={nodeRef} className={styles.ui}>
          <button
            disabled={!slidesMeta.back}
            className={cn(styles.button, styles.buttonBack)}
            onClick={onBack}
          >
            <CarpetIcon className={styles.buttonIcon} />
          </button>
          <div className={styles.slides}>
            {slides.map((slide, index) => (
              <SlidesPreviewItem
                key={slide._id}
                imgSrc={slide.preview.small}
                title={slide.title || t('slide_n', { num: index + 1 })}
                isActive={currentSlideId === slide._id}
                onActive={() => onSlide(slide._id)}
              />
            ))}
          </div>
          <button
            disabled={!slidesMeta.next}
            className={cn(styles.button, styles.buttonNext)}
            onClick={onNext}
          >
            <CarpetIcon className={styles.buttonIcon} />
          </button>
        </div>
      </CSSTransition>
      <HideButton
        className={styles.hideButton}
        direction={isVertical ? 'right' : 'top'}
        isOpen={isOpen}
        onClick={toggleOpen}
      />
    </div>
  );
};

export default SlidesPreview;
