import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppDispatch } from 'store';
import { userJoin } from 'store/actions';
import { getLoggedIn, getToken } from 'store/selectors';
import useAudioContext from 'hooks/audio';
import useAwake from 'hooks/useAwake';
import Spinner from 'ui/Spinner';
import useUserLoginViaService from 'hooks/useUserLoginViaService';

import EnterMeet from './EnterMeet';
import Login from './Login';
import PresentView from './PresentView';

interface PresentProps {
  loginHeader?: React.ReactNode;
  isLinking?: boolean;
  isPresentOnly?: boolean;
  isConferenceOnly?: boolean;
  isDefaultEnter?: boolean;
  isEmbedUI?: boolean;
}

const Present: React.FC<PresentProps> = ({
  loginHeader,
  isLinking = true,
  isPresentOnly = false,
  isConferenceOnly = false,
  isDefaultEnter = false,
  isEmbedUI = false,
}) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(getLoggedIn);
  const token = useSelector(getToken);
  const [isLoading, setLoading] = useState(false);
  const [isEntered, setEnter] = useState(isDefaultEnter);
  useAwake(!isPresentOnly);
  const { resume } = useAudioContext();

  const handleEnter = useCallback(
    () =>
      (isPresentOnly ? Promise.resolve() : resume()).finally(() => {
        setEnter(true);
      }),
    [resume, isPresentOnly],
  );

  const handleUser = useCallback(
    (user: { email: string; name: string }) => {
      handleEnter()
        .then(() => dispatch(userJoin(user)))
        .catch((err) => {
          console.log('jwt is not ready', err);
        });
    },
    [dispatch, handleEnter],
  );

  useUserLoginViaService(setLoading);

  if (isLoading) {
    return <Spinner isCenter />;
  }

  if (isLoggedIn) {
    if (!isEntered) {
      return <EnterMeet onEnter={handleEnter} isAlwaysBack={!isPresentOnly} />;
    }

    return (
      <PresentView
        roomId={id}
        token={token as string}
        isPresentOnly={isPresentOnly}
        isConferenceOnly={isConferenceOnly}
        isEmbedUI={isEmbedUI}
      />
    );
  }

  return (
    <Login
      header={loginHeader}
      isBackButton={isLinking}
      entityType="room"
      entityId={id}
      onUser={handleUser}
    />
  );
};

export default Present;
