import { useTranslation } from 'i18n-lite';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { User, ID } from 'store/models';
import { getCurrentUser } from 'store/selectors';
import { UserTracks } from 'hooks/jitsi/types';
import ConferenceUser from './ConferenceUser';
import CopyBtn from './CopyBtn';
import CallAbilities from './CallAbilities';

import styles from './Conference.module.scss';

import { ReactComponent as CopyIcon } from 'assets/CopyIcon.svg';

interface CallProps {
  meetId: string;
  users: User[];
  isMeetActions?: boolean;
  focusUserId: ID | null;
  setFocusUserId: (userId: ID | null) => void;
  tracks: { [key: string]: UserTracks };
  isLikes: boolean;
  setLikes: (e: boolean) => any;
  userPointers: Set<ID>;
  setPointer: (userId: ID, e: boolean) => any;
  mutedUsers: Set<ID>;
  setMuted: (userId: ID, e: boolean) => any;
  isSelfPointer: boolean;
  setSelfPointer: (e: boolean) => any;
  isPresenter: boolean;
}

const Call: React.FC<CallProps> = ({
  meetId,
  users,
  isMeetActions = true,
  focusUserId,
  setFocusUserId,
  tracks,
  isLikes,
  setLikes,
  userPointers,
  mutedUsers,
  setMuted,
  setPointer,
  isPresenter,
  isSelfPointer,
  setSelfPointer,
}) => {
  const { t } = useTranslation('conference');
  const currentUser = useSelector(getCurrentUser);

  const handleFocusId = useCallback(
    (userId: ID, val: boolean) => {
      setFocusUserId(val ? userId : null);
    },
    [setFocusUserId],
  );

  return (
    <>
      <div className={styles.tabContent}>
        {users.map((user) => {
          const isCurrentUser = user._id === currentUser?._id;

          return (
            <ConferenceUser
              key={user._id}
              isMeetActions={isMeetActions}
              isCurrentUser={isCurrentUser}
              className={styles.confUser}
              user={user}
              isPresenter={isPresenter}
              isPointer={userPointers.has(user._id)}
              setMuted={setMuted}
              isFocused={focusUserId === user._id}
              setFocus={handleFocusId}
              isMuted={mutedUsers.has(user._id)}
              setPointer={setPointer}
              tracks={isCurrentUser ? tracks.currentUser : tracks[user._id]}
              isAudioTrack={false}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        {isPresenter && isMeetActions && (
          <CallAbilities
            isLikes={isLikes}
            setLikes={setLikes}
            isSelfPointer={isSelfPointer}
            setSelfPointer={setSelfPointer}
          />
        )}
        <div className={styles.meetId}>
          <div className={styles.meetIdCaption}>{t('meetId')}</div>
          <CopyBtn content={meetId}>
            {meetId}
            <CopyIcon />
          </CopyBtn>
        </div>
      </div>
    </>
  );
};

export default Call;
