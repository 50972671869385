import { sendLogs as apiLogger } from 'api';
import throttle from 'lodash/throttle';

let events: any[] = [];

const TIMEOUT = 1000 * 10;

const sendLogs = throttle(
  async () => {
    const pendingEvents = [...events];
    events = [];

    apiLogger({ logs: pendingEvents });
  },
  TIMEOUT,
  { leading: false },
);

export function log(event: any) {
  events.push(event);

  sendLogs();
}

export const isDebug = window.location.search.includes('debug-front');

export const isTraceJitsi = isDebug || window.location.search.includes('trace-jitsi');

export const isPassTeams = isDebug || window.location.search.includes('trace-teams');
