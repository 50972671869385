import { useMemo } from 'react';
import { useTranslation } from 'i18n-lite';
import useFrameAspect from 'hooks/useFrameAspect';
import { MediaTrack } from 'hooks/jitsi/types';
import UserVideo from '../UserVideo';

import styles from './Canvas.module.scss';

import { ReactComponent as DesktopIcon } from 'assets/DesktopOnIcon.svg';

interface VideoCanvasProps {
  video: MediaTrack;
  zoom?: number;
}

const VideoCanvas: React.FC<VideoCanvasProps> = ({ video, zoom = 1 }) => {
  const { t } = useTranslation('presentation');
  const { wrapperRef, frameRef, frameWrapperStyles } = useFrameAspect({ zoom });

  const isPlaceholder = useMemo(() => video.isLocal() && video.videoType === 'desktop', [video]);

  return (
    <div className={styles.canvas} ref={wrapperRef as any}>
      <div ref={frameRef} className={styles.frameWrapper} style={frameWrapperStyles}>
        {isPlaceholder ? (
          <div className={styles.placeholder}>
            <DesktopIcon className={styles.placeholderIcon} />
            <p>{t('screenShare.placeholder')}</p>
          </div>
        ) : (
          <UserVideo className={styles.video} track={video} />
        )}
      </div>
    </div>
  );
};

export default VideoCanvas;
